/*================== Modal ===============*/
.modal {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .modal:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
  }

  .modal-dialog{
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      margin: 10px;

      &.modal-lg{
        width: 100%;
      }

  }

  .modal-dialog .modal-content{
      border-radius: 2px;
      border: 0;
      -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18),0 4px 15px 0 rgba(0, 0, 0, .15);
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18),0 4px 15px 0 rgba(0, 0, 0, .15);
  }

  .modal .modal-content .modal-header{
      text-align: center;
      padding: 1.5rem;
      border: none;
      background-color: #8a7bd1;
  }

  .modal .modal-content .modal-header .title{
      display: inline-block;
      margin-bottom: 0;
      color: #ffffff;
      line-height: 40px;
  }

  .modal .modal-content .modal-header .close{
      color: #fff;
      opacity: 1;
      text-shadow: none;
      font-size: 30px;
      font-weight: 700;
  }
  .modal .contact-text{
      margin-bottom: 10px;
  }
  .modal .contact-text p{
      color: #888;
      font-size:16px;
      line-height: 18px;
  }

  span.req{
      color: red;
  }
  .req-text{
      color: red;
      font-size: 12px;
      font-weight: 300;
  }