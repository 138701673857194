/*----------  Fonts Mixins  ----------*/
@font-face {
    font-family: 'bubblegum';
    src: url(/front/fonts/Bubblegum_Sans/BubblegumSans-Regular.ttf);
}

@font-face {
    font-family: 'dosis-bold';
    src: url(/front/fonts/Dosis/Dosis-Bold.ttf);
}

@font-face {
    font-family: 'dosis-wxtrabold';
    src: url(/front/fonts/Dosis/Dosis-ExtraBold.ttf);
}

@font-face {
    font-family: 'dosis-extralight';
    src: url(/front/fonts/Dosis/Dosis-ExtraLight.ttf);
}

@font-face {
    font-family: 'dosis-light';
    src: url(/front/fonts/Dosis/Dosis-Light.ttf);
}

@font-face {
    font-family: 'dosis-medium';
    src: url(/front/fonts/Dosis/Dosis-Medium.ttf);
}

@font-face {
    font-family: 'dosis-semibold';
    src: url(/front/fonts/Dosis/Dosis-SemiBold.ttf);
}

@font-face {
    font-family: 'dosis';
    src: url(/front/fonts/Dosis/Dosis-Regular.ttf);
}

@font-face {
    font-family: 'Rancho';
    src: url(/front/fonts/Rancho/Rancho-Regular.ttf);
}



/*----------  Typography  ----------*/
//heading classes
h1{
    @include font-p($font-xl + rem-calc(8), $weight-regular, $font-xl + rem-calc(8), $brand-secondary);

    @include media-breakpoint-down(md) {
        @include font-p($font-xl, $weight-regular, $font-xl + rem-calc(2), $brand-secondary);
    }
}

h2{
    @include font-p($font-xl, $weight-regular, $font-xl + rem-calc(2), $brand-secondary);
    @include media-breakpoint-down(md) {
        @include font-p($font-lg, $weight-regular, $font-lg + rem-calc(2), $brand-secondary);
    }
}

h3 {
    @include font-p($font-lg, $weight-regular, $font-lg + rem-calc(2), $brand-secondary);
    @include media-breakpoint-down(md) {
        @include font-p($font-md, $weight-regular, $font-md + rem-calc(2), $brand-secondary);
    }
}

h4 {
    @include font-p($font-md, $weight-regular, $font-md + rem-calc(2), $brand-secondary);
    @include media-breakpoint-down(md) {
        @include font-p($font-sm, $weight-regular, $font-sm + rem-calc(2), $brand-secondary);
    }
}

h5 {
    @include font-p($font-sm, $weight-regular, $font-sm + rem-calc(2), $brand-secondary);
    @include media-breakpoint-down(md) {
        @include font-p($font-xs, $weight-regular, $font-xs + rem-calc(2), $brand-secondary);
    }
}

h6 {
    @include font-p($font-xs, $weight-regular, $font-xs + rem-calc(2), $brand-secondary);
}

.section-title{
    h1,h2,h3,h4,h5,h6{
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;

        &:after{
            position: absolute;
            content: "";
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 10px;
            background: url(/front/images/icons/sep-1.png) center left no-repeat;
        }

        span{
            color: $brand-primary;
        }
    }

    &.text-center, &.center{
        h1,h2,h3,h4,h5,h6{
            &:after{
                background: url(/front/images/icons/sep-1.png) center center no-repeat !important;
            }
        }
    }
}

body{
    p{
        @include font-s($font-md, $weight-regular, $font-md + rem-calc(10), $brand-gray-01);
    }

    a{
        @include font-p($font-md, $weight-regular, $font-md, $brand-secondary);
        text-decoration: none;
    }
}
