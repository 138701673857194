/*
===========================
    event style
==========================
*/



.event {
    @include position;
    padding: 120px 0 120px;

    .link-btn {
        margin-top: 50px;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: url(/front/images/overly/event.png) center bottom no-repeat;
        z-index: 0;
    }

    .img-holder {
        position: relative;
        border-radius: 0 15px;
        background: #d0d0d0;
        overflow: hidden;

        figure{
            margin: 0;
        }

        a{
            width: 100%;
        }

        img {
            width: 100%;
            height: 300px;
        }
    }

    .content {
        @include position;

        &.bg-color-1{
            background: #ec4f74;
        }
        &.bg-color-2{
            background: #adcb69;
        }
        &.bg-color-3{
            background: #f8b54d;
        }

    }

    .inner-box {
        position: relative;
        padding: 25px;

        .btn-box {
            position: absolute;
            left: 20px;
            top: 24px;
            border: 1px solid #fff;
            height: 65px;
            width: 65px;
            text-align: center;
            padding-top: 8px;
            border-radius: 0 10px;
        }

        .count {
            position: relative;
            color: #fff;
            font-family: 'bubblegum';
            font-size: 27px;
            line-height: 24px;
        }

        .month {
            position: relative;
            color: #fff;
            font-family: 'bubblegum';
            font-size: 18px;
            line-height: 24px;
        }

        h4 {
            margin-top:5px;
            margin-bottom:2px;
            font-size: 20px;

            a {
                color: #fff;
                transition: .5s ease;

                &:hover {
                    color: #232323;
                    transition: .5s ease;
                }
            }
        }

        p {
            color: #fff;
            font-size: 15px;
            line-height: 26px;

            &.small{
                margin-top: 20px;
                @include font-p($font-sm, $weight-light, $font-sm + rem-calc(2), $white-color);
            }

            span {
                color: #fff;
                font-size: 14px;
                margin-right: 6px;
            }
        }
    }

    ul.tag-items{

        li{
            padding: 0 10px;
            margin: 10px;
            background-color: $brand-primary;
            border: 1px solid $brand-secondary;
            border-radius: 0 5px 0 5px;

            a{
                @include font-s($font-sm, $weight-bold, $font-sm, $white-color);
            }

            &.active{
                background-color: $brand-success;
            }
        }
    }

}


.event.style-2:before {
    display: none;
}

.event.style-2 .item {
    margin-bottom: 30px;
}