/*----------  Header  ----------*/

header
{
	@include position(relative, 999, $left:0);
	width: 100%;
}

.pre-header
{
	background-color: rgba($brand-primary, 0.8);
	text-align: center;
	height: 70px;
	@include position(absolute, 999, 0, 0);
	width: 100%;

	.close-btn {
		display: none;
	}

	p{
		display: inline-block;
		@include font-p($font-md, $weight-light, 70px, $white-color);
		margin-right: 140px;
	}

	@include media-breakpoint-down(lg){
		p {
			margin-right: 30px;
		}
	}

	@include media-breakpoint-down(md){
		background-color: $brand-primary;
		.pre-header-container {
			@include display-flex(column, center, center);
		}

		.pre-header-wrapper {
			padding: 30px 0;
			@include position;
		}

		.close-btn {
			display: block;
			@include absolute-center(x);
			@include transform(translate(-50%, -50%));
			background-color: $brand-primary;
			@include border-radius(999px);
			height: 50px;
			width: 50px;
			color: $white-color;
			font-size: 30px;
			@include display-flex(column, center, center);
		}

		p {
			margin-right: 0;
			line-height: 2;
			margin-bottom: 30px;
		}
		// display: none;
		height: auto;
		@include position(fixed, 99, $bottom: 0, $top: auto);
	}
}
