/*----------  Property Table  ----------*/

.property-table-container
{
	background: $brand-gray-02;

	.property-table-inner
	{
		padding-left: 160px;
		padding-bottom: 170px;

		@include position;

		@include media-breakpoint-down(lg)
		{
			padding: 0;
			padding-top: 60px;
			padding-bottom: 100px;
		}

		@include media-breakpoint-down(sm)
		{
			padding-top: 40px;
			padding-bottom: 60px;
		}
	}

	.table-filter-container
	{
		width: 140px;

		@include position(absolute, 11, 0, 0);

		.filter-area
		{
			width: 70px;
			// padding: 16px 0;
			background: $white-color;

			@include position;
			@include border-radius(30px);
			@include box-shadow(0 10px 20px 0 rgba($brand-primary, 0.05));

			.filter-containment
			{
				width: 100%;
				height: calc(100% - 32px);

				@include transform(translateY(-50%));
				@include position(absolute, -1, 50%, 0);
			}

			.filter-handle
			{
				height: 50px;
				width: 140px;
				cursor: grab;
				background: $brand-primary;

				@include border-radius(30px);
				@include position(absolute, 5, 16px, -10px);
				@include display-inline-flex(center, space-between);

				.handle-text
				{
					display: none;

					@include font-p(rem-calc(17), $weight-regular, 1.2, $white-color);
				}

				.handle-icon
				{
					@include transform(translateY(-50%));
					@include position(absolute, 5, $top: 50%, $right: 15px);
					@include font-p(rem-calc(12), $weight-regular, 1.2, $white-color);

					i, span
					{
						display: block;
						cursor: pointer;
					}
				}
			}

			.filter-steps
			{
				// @include pointer-events(none);
				@include position(relative, 11);

				.filter-step
				{
					padding: 16px 0;
					cursor: pointer;
					@include position;
					@include display-flex(column, center, center);

					.filter-serial
					{
						width: 100%;
						height: 50px;
						text-transform: uppercase;

						@include flex(1 0 50px);
						@include display-flex(column, center, center);
						@include font-p(rem-calc(17), $weight-regular, 1.2, $brand-primary);
					}

					.filter-snap
					{
						height: 50px;
						width: 140px;

						@include pointer-events(none);
						@include transform(translateY(-50%));
						@include position(absolute, -1, 50%, -10px);
					}

					&.active
					{
						.filter-serial
						{
							color: $white-color;
						}
					}
				}
			}
		}

		@include media-breakpoint-down(lg)
		{
			display: none;
		}
	}

	.table-container
	{
		@include flex(1);

		.table-title
		{
			margin-bottom: 30px;

			@include position;
			@include display-inline-flex(center, space-between);

			h2
			{
				color: $brand-primary;

				@include media-breakpoint-down(sm)
				{
					font-size: rem-calc(24);
				}
			}

			.title-text, .title-action
			{
				margin-bottom: 30px;

				@include media-breakpoint-down(sm)
				{
					margin-bottom: 20px;
				}
			}

			@include media-breakpoint-down(md)
			{
				text-align: center;
				margin-bottom: 10px;

				@include flex-direction(column);
				@include justify-content(normal);
			}
		}

		.table-filter
		{
			display: none;
			margin-bottom: 30px;

			@include position(relative, 5);

			@include media-breakpoint-down(lg)
			{
				display: block;
			}
		}

		.table-contents
		{
			@include position;

			.table.dataTable
			{
				text-align: center;

				.th-thead
				{
					border: 0;
					padding: 20px;
					// min-width: 100px;
					white-space: nowrap;
					letter-spacing: 1.2px;
					vertical-align: middle;
					text-transform: uppercase;

					@include position;
					@include font-sub($font-xs, $weight-regular, 1.5, $brand-primary);

					.th-sort-icon
					{
						display: block;

						@include opacity(0);
						@include transition;
						@include transform(translateY(-50%));
						@include position(absolute, 5, $top: 50%, $right: 0);

						svg
						{
							display: block;
						}
					}

					&.sorting_asc, &.sorting_desc
					{
						.th-sort-icon
						{
							@include opacity(1);
						}
					}

					&.sorting_desc
					{
						.th-sort-icon
						{
							@include transform(translateY(-50%) scaleY(-1));
						}
					}

					&:before, &:after
					{
						display: none;
					}

					@include media-breakpoint-down(md)
					{
						padding: 15px;
						// min-width: 70px;
					}
				}

				.td-tbody
				{
					border: 0;
					padding: 15px 20px;
					white-space: nowrap;
					vertical-align: middle;
					border-top: 1px solid $brand-gray-03;

					@include transition;
					@include font-p(rem-calc(17), $weight-regular, 1.4, $brand-primary);

					a, .btn
					{
						white-space: nowrap;
					}

					.btn
					{
						@include transition;

						&:hover, &:active, &:focus
						{
							@include button-theme($white-color, $brand-danger, transparent);
						}
					}

					.atn-link
					{
						border-bottom: 2px solid $brand-danger;

						@include position;
						// @include opacity(0);
						@include font-inherit;

						&:after
						{
							content: '';
							height: 2px;
							width: 100%;
							background: $brand-primary;
							transform-origin: left top;

							@include transition;
							@include transform(scaleX(0));
							@include position(absolute, 2, $bottom: -2px, $left: 0);
						}

						&:hover, &:active, &:focus
						{
							&:after
							{
								@include transform(scaleX(1));
							}
						}
					}

					&:first-child
					{
						@include position;

						&:before
						{
							content: '';
							height: 0;
							width: 3px;
							background: $brand-danger;

							@include transition;
							@include position(absolute, 5, 0, 0);
						}

						@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
						{
							border-left: 3px solid transparent;

							&:before
							{
								display: none;
							}
						}
					}

					@include media-breakpoint-down(md)
					{
						padding: 15px;
						font-size: $font-sm;
					}
				}

				.tr-tbody
				{
					@include transition;

					&.tr-sold
					{
						.td-tbody
						{
							background: rgba($brand-danger, 0.05) !important;

							&.td-prix-htva
							{
								color: $brand-gray-01 !important;
							}
						}
					}

					&:hover, &:active, &:focus
					{
						@include box-shadow(0 6px 20px 0 rgba($brand-primary, 0.05));

						> .td-tbody
						{
							background: $white-color;

							.btn:not(:hover)
							{
								@include button-theme($white-color, $brand-primary, transparent);
							}

							.atn-link
							{
								@include opacity(1);
							}

							&:first-child
							{
								&:before
								{
									height: 100%;
								}

								@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
								{
									border-left-color: $brand-danger;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 70px;
		}

		@include media-breakpoint-down(sm)
		{
			margin-bottom: 30px;
		}
	}

	.table-cta-container
	{
		width: 100%;
		text-align: center;

		@include pointer-events(none);
		@include transform(translateY(50%));
		@include position(absolute, 5, $bottom: 0, $left: 0);

		.btn
		{
			@include pointer-events(all);

			@include media-breakpoint-down(xs)
			{
				padding-left: 15px;
				padding-right: 15px;
				font-size: rem-calc(9);
			}
		}

		@include media-breakpoint-down(lg)
		{
			@include reset-position;
		}
	}
}
