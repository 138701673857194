/*----------  Index View  ----------*/


/*----------  Main Slider View  ----------*/

/*----------  Bootstrap Slider  ----------*/
.main-slider-container{

	.carousel-indicators{
		li{
			height: 12px;
			width: 12px;
			margin: 5px;
			border: 2px solid rgba(71, 71, 71, 0.5);
			transition: all 0.3s ease-in-out;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			border-radius: 50%;

			&.active{
				width: 24px;
				border-radius: 10px;
				border: 2px solid #f8b54d;
			}
		}
	}

	.carousel{
		@include position;
		border-radius: 10px;

		.carousel-control-prev,
		.carousel-control-next{
			opacity: 0;
			@include transition;
		}

		&:hover{
			.carousel-control-prev,
			.carousel-control-next{
				opacity: 1;
			}
		}

		.carousel-caption{
			@include position($position:absolute, $left: 0, $bottom: 0, $right: auto);
			background-color: rgba(0, 0, 0, 0.6);
			padding: 20px;
			text-align: left;

			h2{
				@include font-p($font-lg, $weight-medium, $font-lg + rem-calc(2), $white-color);
				margin-bottom: 10px;
			}

			p{
				@include font-s($font-sm, $weight-regular, $font-sm + rem-calc(2), rgba(255, 255, 255, 0.6));
			}
		}
	}

}

/*----------  Slick Slider  ----------*/
.main-slider {
    opacity: 0;
    visibility: hidden;
    transition: opacity 3s ease;
	-webkit-transition: opacity 3s ease;

	&.slick-initialized{
		visibility: visible;
    	opacity: 1;
	}
}

.main-slider-container{
	@include position;

	.main-slick-nav{
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15%;
		color: #fff;
		text-align: center;
		opacity: 0.5;
		transition: opacity 0.15s ease;

		span{
			font-size: 32px;
		}
	}

	.main-slider-prev{
		left: 0;
	}

	.main-slider-next{
		right: 0;
	}

	.main-slider-dots-container{
		@include absolute-center(x);
		top: auto;
		bottom: 20px;

		.slick-dots {
			li {
				.slick-dots-circle {
					border: 2px solid rgba($white-color, 0.5);
				}

				&.slick-active {
					.slick-dots-circle {
						width: 24px;
						@include border-radius(10px);
						border: 2px solid $brand-primary;
					}
				}
			}
		}
	}

	.slider-item{
		@include position;

		.carousel-caption{
			@include position($position:absolute, $left: 0, $bottom: 0, $right: auto);
			background-color: rgba(0, 0, 0, 0.6);
			padding: 20px;
			text-align: left;

			h2{
				@include font-p($font-lg, $weight-medium, $font-lg + rem-calc(2), $white-color);
				margin-bottom: 10px;
			}

			p{
				@include font-s($font-sm, $weight-regular, $font-sm + rem-calc(2), rgba(255, 255, 255, 0.6));
			}
		}

	}


}

/*----------  content View  ----------*/
.about{
	.img-box{
		img{
			border-radius: 0 15px 0 15px;
		}
	}
}

.see-more {
    position: relative;
    background: #f9f9f9;
	.see-more-inner{
		padding: 45px;
		@include display-inline-flex(center, space-between);
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
}

/*----------  Feature Items  ----------*/
.feature {
	position: relative;
	padding: 120px 0 0px;

	@include media-breakpoint-down(md) {
		padding: 60px 0 0px;
	}

	.column {
	  padding-bottom: 53px;

	  @include media-breakpoint-down(md) {
			padding-bottom: 0;
		}
	}

	.item {
	  &.right {
		position: relative;
		padding-right: 70px;
		margin-bottom: 60px;
		text-align: right;

		@include media-breakpoint-down(md) {
			text-align: left;
			padding-left: 70px;
			padding-right: auto;
		}

		@include media-breakpoint-down(sm) {
			padding-right: 35px;
			margin-bottom: 30px;
		}
	  }

	  &.left {
		position: relative;
		padding-left: 70px;
		margin-bottom: 60px;

		@include media-breakpoint-down(sm) {
			padding-right: 35px;
			margin-bottom: 30px;
		}
	  }

	  &.right {
		&:nth-child(1) {
		  right: -21px;
		}

		&:nth-child(2) {
		  right: 31px;
		}

		&:nth-child(3) {
		  right: 74px;
		}

		@include media-breakpoint-down(md) {
			right: auto !important;
		}
	  }

	  &.left {
		&:nth-child(1) {
		  left: -21px;
		}

		&:nth-child(2) {
		  left: 31px;
		}

		&:nth-child(3) {
		  left: 74px;
		}

		@include media-breakpoint-down(md) {
			left: auto !important;
		}
	  }

	  .icon {
		position: absolute;
		top: 0;
		height: 42px;
		width: 42px;
		border-radius: 0 10px;
		text-align: center;
		transition: .5s ease-in;

		@include media-breakpoint-down(md) {
			left: 0;
			right: auto !important;
		}

	  }

	  &.right {
		&:nth-child(1) .icon {
		  background: #d07dd2;
		  border: 2px solid #d07dd2;
		  right: 0;
		}

		&:nth-child(2) .icon {
		  background: #adcb69;
		  border: 2px solid #adcb69;
		  right: 0;
		}

		&:nth-child(3) .icon {
		  background: #f8b54d;
		  border: 2px solid #f8b54d;
		  right: 0;
		}
	  }

	  &.left {
		&:nth-child(1) .icon {
		  background: #fc5b4e;
		  border: 2px solid #fc5b4e;
		  left: 0;
		}

		&:nth-child(2) .icon {
		  background: #8373ce;
		  border: 2px solid #8373ce;
		  left: 0;
		}

		&:nth-child(3) .icon {
		  background: #84bed6;
		  border: 2px solid #84bed6;
		  left: 0;
		}
	  }

	  .icon span:before {
		position: relative;
		color: #fff;
		line-height: 39px;
		font-size: 20px;
	  }

	  h4 {
		margin-bottom: 16px;
		font-size: 22px;

		a {
		  position: relative;
		  transition: .5s ease;
		}
	  }

	  &.right {
		&:nth-child(1) h4 a {
		  color: #d07dd2;
		}

		&:nth-child(2) h4 a {
		  color: #adcb69;
		}

		&:nth-child(3) h4 a {
		  color: #f8b54d;
		}
	  }

	  &.left {
		&:nth-child(1) h4 a {
		  color: #fc5b4e;
		}

		&:nth-child(2) h4 a {
		  color: #8373ce;
		}

		&:nth-child(3) h4 a {
		  color: #84bed6;
		}
	  }

	  h4 a:hover {
		color: inherit;
	  }

	  p {
		position: relative;
		color: #777;
		font-size: 16px;
		line-height: 26px;
	  }
	}

	.img-box {
	  position: relative;
	  margin-left: 50px;
	  top: -26px;
	}
  }

  .messagebox-section{
	  position: relative;
	  padding: 60px 0;

	&:before{
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background: url(/front/images/overly/team.png) center center no-repeat;
		background-size: cover;
		z-index: 0;
	}

	h1{
		color: $brand-primary;
		display: inline-block;
	}

	.message-wrapper{
		margin-top: 20px;

		h2{
			margin-top: 20px;
			color: $black-color;
		}
	}

	.message{
		text-indent: 50px;
		text-align: justify;
	}

	img{
		&.person{
			width: 400px;
			float: left;
			border-radius: 0 15px 0 15px;
			margin: 0 20px 20px 0;

			@include media-breakpoint-down(sm) {
				float: none;
				width: 100%;
			}
		}
	}
  }

  .gallery-section{
	  padding: 120px 0 20px;
	  @include position;

	  &:before{
		content: '';
		@include position(absolute, 0, 0);
		width: 100%;
		height: 100%;
		background: url(/front/images/overly/gallery.png) center center no-repeat;
		background-size: cover;
		z-index: 0;
	  }

	  .slick-slider{
		text-align: center;
	  }

	  .gallery-item{
		  margin-bottom: 30px;

		.image-box{
			margin: 0;

			img{
				position: relative;
				display: block;
				border-radius: 0 15px 0 15px;
			}
		}
	  }

	  .inner-box{
		  @include position;
		  overflow: hidden;

		  &:hover{
			  .overlay-box{
				  top: 0px;
				  @include transform(1);
			  }
		  }
	  }

	  .overlay-box{
		@include position(absolute, $top:-100%, $left: 0);
		width: 100%;
		height: 100%;
		text-align: center;
		color: $white-color;
		@include transition;
	  }

	  .overlay-inner{
		@include position(absolute, $top:0, $left: 0);
		width: 100%;
		height: 100%;
		border-radius: 0 15px;
		display: table;
		vertical-align: middle;
		padding: 10px 30px;
		background: rgba(248, 181, 77, 0.8);

		.content{
			position: relative;
			display: table-cell;
			vertical-align: middle;
		}

		h3{
			font-size: 18px;
			color: $white-color;
			font-weight: 500;
			text-transform: capitalize;
			margin-bottom: 20px;

			a{
				color: $white-color;
			}
		}

		.image-link{
			margin-right: 12px;
		}

		.image-link, .image-link2{
			@include position;
			width: 42px;
			height: 42px;
			color: $white-color;
			line-height: 42px;
			border: 1px solid $white-color;
			display: inline-block;
			font-size: 16px;
			text-align: center;
			border-radius: 0 7px;
		}

	  }

	  .see-more{
		padding-top: 15px;
		background: transparent;

		h2{
			line-height: 60px;
		}
	  }
  }

  .two-columns{
	  @include position;
	  padding: 120px 0 150px;
		@include media-breakpoint-down(md) {
			padding: 60px 0;
		}

	  &:before{
		content: '';
		@include position(absolute, $top:0, $left: 0);
		width: 100%;
		height: 100%;
		background: url(/front/images/overly/testimonial.png) center bottom no-repeat;
		z-index: 0;
	  }

	  .section-title{
		  padding-bottom: 50px;
		@include media-breakpoint-down(md) {
			padding-bottom: 20px;
			text-align: center;

			h2{
				&:after{
					background-position: center center;
				}
			}
		}
	  }
	.content-column {
		position: relative;
		margin-bottom: 65px;
		.content {
			position: relative;
			margin-right: 80px;
			padding: 30px 40px;
			border: 1px solid #ececec;
			border-radius: 0 15px;

			@include media-breakpoint-down(md) {
				margin: 0;
			}
			h2 {
				position: relative;
				font-size: 20px;
				color: #f8b54d;
				margin-bottom: 18px;
			}
			p {
				position: relative;
				font-size: 17px;
				color: #777;
				line-height: 26px;
				margin-bottom: 30px;
			}
			a {
				position: relative;
				font-family: 'dosis';
				font-size: 17px;
				color: #f8b54d;
				transition: .5s ease;
				&:hover {
					color: #232323;
					transition: .5s ease;
				}
			}
		}
	}
  }

  .class-activities{

	.tab-list {
		li {
			@include position;
			display: inline-block;
			text-align: center;
			padding: 10px 0px;
			width: 150px;
			border-radius: 0 10px;
			margin-right: 6px;
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				width: 100%;
			}

			&:nth-child(1) {
				background: #adcb69;
			}
			&:nth-child(2) {
				background: #ec4f74;
			}
			&:nth-child(3) {
				background: #5bc89f;
			}
			&:nth-child(4) {
				background: #1cc6df;
			}
			&:nth-child(5) {
				background: #c428e0;
			}
			&:nth-child(6) {
				background: #22eff7;
			}
			a {
				position: relative;
				color: #fff;
				font-size: 20px;
			}

			&.hvr-bubble-bottom{
				&:nth-child(1) {
					&:before {
						border-color: #adcb69 transparent transparent transparent;
					}
				}
				&:nth-child(2) {
					&:before {
						border-color: #ec4f74 transparent transparent transparent;
					}
				}
				&:nth-child(3) {
					&:before {
						border-color: #5bc89f transparent transparent transparent;
					}
				}
				&:nth-child(4) {
					&:before {
						border-color: #1cc6df transparent transparent transparent;
					}
				}
				&:nth-child(5) {
					&:before {
						border-color: #c428e0 transparent transparent transparent;
					}
				}
				&:nth-child(6) {
					&:before {
						border-color: #22eff7 transparent transparent transparent;
					}
				}
			}
		}

		li.active.hvr-bubble-bottom {
			&:before {
				-webkit-transform: translateY(10px);
				transform: translateY(10px);
			}
		}
	}

	&.two-columns{
		.content-column .content{
			margin: 0;
		}
	}
  }


/*----------  Testimonial  ----------*/
  .testimonial{
	@include position;

	@include media-breakpoint-down(md) {
		margin-bottom: 50px;
	}

	.testimonial-slide-wrapper{
		@include position;
	}

	.testimonial-slide{
		.item{
			@include position;
			padding: 10px 30px 45px;
			// border: 1px solid $brand-gray-01;
			box-shadow: 0 11px 29px 0 rgba(0,0,0,0.04), 0px 0px 2px 0 rgba(0, 0, 0, 0.04);
			border-radius: 0 15px;
			margin: 9px 10px 30px;

			img{
				width: auto;
				display: inline-block;
				width: 75px;
				height: 75px;
			}

			p{
				color: #777;
				line-height: 26px;
				margin: 16px 0 12px;
			}

			.rating{
				span{
					color: $brand-primary;
				}
			}

			.link{
				@include absolute-center(x){
					top: auto;
					bottom: -20px;
				}
			}

			.btn-style-one{
				position: relative;
				color: #fff;
				font-size: 17px;
				font-family: 'dosis-bold';
				padding: 6px 34px;
				background: #77cabd;
				z-index: 9;
				border-radius: 0 10px;
			}
		}
	}
  }

  /** Students Clubs */
  .student-club{
	.img-holder{
		text-align: center;
	}
  }

  /** student-corner **/
  .students-corner{
	  .section-title{
		  margin-bottom:20px
		}
		.img-holder figure{
			max-width:370px;
			margin:0 auto;
			text-align: center;
		}
		.inner-box{
			padding-left:0;
			padding:24px 20px
		}
  }