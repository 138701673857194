/*----------  Footer  ----------*/

 /*----------  Footer  ----------*/
 footer{
	@include position;

	/***
	====================================================================
	footer-style-1 style
	====================================================================
	***/

	&.footer-style-1{

		.footer-upper{
			@include position;
			padding: 120px 0 50px;

			.item{
				margin-bottom: 64px;

			.icon span:before {
				position: relative;
				line-height: 90px;
				font-size: 30px;
			}

			h4 {
				margin-bottom:16px;
				font-size: 25px;

				a {
					position:relative;
					transition: .5s ease;
				}
			}

			p {
				position:relative;
				color:#777;
				font-size:17px;
				line-height: 26px;
			}

			.icon{
				@include position;
				height: 90px;
				color: #fff;
				width: 90px;
				margin: 0 auto;
				border-radius: 0 15px 0 15px;
				text-align: center;
				transition: .5s ease-in;
				margin-bottom: 20px;
				cursor: pointer;

				span{
					&:before{
						@include position;
						line-height: 90px;
						font-size: 30px;
					}
				}
			}
			}

		.column:nth-child(1) .item .icon {
			background: #adcb69;
			border: 2px solid #adcb69;

			h4 a {
				color: #adcb69;
			}
		}
		.column:nth-child(2) .item .icon {
			background: #ec4f74;
			border: 2px solid #ec4f74;

			h4 a {
				color: #ec4f74;
			}
		}
		.column:nth-child(3) .item .icon {
			background: #5bc89f;
			border: 2px solid #5bc89f;

			h4 a {
				color: #5bc89f;
			}
		}
		.column:nth-child(1) .item:hover .icon {
			background: transparent;
			color: #adcb69;
			transition: .5s ease-in;
		}
		.column:nth-child(2) .item:hover .icon {
			background: transparent;
			color: #ec4f74;
			transition: .5s ease-in;
		}
		.column:nth-child(3) .item:hover .icon {
			background: transparent;
			color: #5bc89f;
			transition: .5s ease-in;
		}
		}

		.footer-bottom-wrapper{
			@include display-inline-flex;
			@include media-breakpoint-down(md) {
				flex-direction: column;
			}

			.site-logo{
				@include flex-grow(4);
				margin-right: 50px;
			}
		}

		.footer-bottom{
		position: relative;
		background: url(/front/images/background/footer-bg.png) center center no-repeat;
		background-position: center top;
		padding-top: 50px;

		&:before {
			content: '';
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			background:url(/front/images/overly/pattern.png) center center no-repeat;
			background-size: cover;
			z-index: 0;
		}

		}

		.footer-bottom-bg {
			background-color: #77cabd;
			padding: 20px 0 77px;
		}

		.pull-left {
			margin-top: 27px;
			position: relative;
		}

		.menu {
			position: relative;
			border-bottom: 1px solid #bbe5de;

			ul {
				position: relative;
				list-style-type: none;

				li {
					position: relative;
					float: left;
					margin-left: 36px;
					@include media-breakpoint-down(md) {
						margin-left: 16px;
					}

					@include media-breakpoint-down(sm) {
						float: none;
						display: block;
						margin: 0;
						text-align: center;
					}

					&:first-child {
						margin-left: 0px;
					}

					a {
						position: relative;
						line-height: 69px;
						font-size: 20px;
						color: #fff;
						text-transform: capitalize;
						transition: .5s ease;

						@include media-breakpoint-down(sm) {
							line-height: 40px;
						}

						&:hover {
							color: #ff9a00;
							transition: .5s ease;
						}

					}
				}
			}
		}

		.copy-right {
			float: right;
			position: relative;
			line-height: 59px;
			font-size: 17px;
			color: #fff;
			font-family: "dosis";

			span {
				color: #ff9a00;

			}

		}

		.logo-img{
			max-width: 300px;
		}

	}

	/***
	====================================================================
	footer-style-2 style
	====================================================================
	***/

	&.footer-style-2 {
		position: relative;
		background: #e3f7ff;
		// background: #000000;
		padding: 105px 0 230px;
		margin-top: 120px;

		&:before {
			content: '';
			position: absolute;
			left: 0px;
			top: -105px;
			width: 100%;
			height: 832px;
			background: url(/front/images/overly/footer-style2.png) center bottom no-repeat;
			z-index: 0;
		}

		.footer-upper {
			position: relative;
			padding-bottom: 20px;
			border-bottom: 1px solid #f1fbff;
		}

		.column-title{
			h2 {
				position: relative;
				text-transform: capitalize;
				font-size: 28px;
				color: #ec4f74;
				padding-bottom: 13px;
				margin-bottom: 26px;

				&:after {
					position: absolute;
					content: "";
					left: 0px;
					bottom: 0px;
					width: 100%;
					height: 10px;
					background: url(/front/images/icons/sep-3.png) center left no-repeat;
				}
			}
		}

		.footer-widget-about{
			.content{
				p {
					position: relative;
					font-size: 16px;
					color: #888;
					margin-bottom: 24px;
				}

				ul li {
					position: relative;
					overflow: hidden;
					padding: 12px 0;

					a {
						position: relative;
						font-size: 15px;
						font-family: 'dosis';
						color: #888;
						float: left;
					}

					span {
						position: relative;
						margin-right: 20px;
						font-size: 17px;
						float: left;
						top: 2px;
					}
				}
			}
		}

		.news-column {
			padding-bottom: 20px;

			.news-post{
				position:relative;
				padding:7px 0px 0px 100px;
				margin-bottom:35px;
				color:#888;
				line-height:20px;

				a {
					position:relative;
					color:#232323;
					font-size: 18px;
					transition: .5s ease;
				}

				.time{
					position:relative;
					color:#888;
					font-size:13px;
					font-family: 'dosis-medium';
					margin-top: 5px;
				}

				.image-thumb{
					position:absolute;
					left:0px;
					top:0px;

					img{
						width: auto;
					}
				}
			}
		}

		.links-column {
			margin-bottom:30px;

			.column-title h2 {
				margin-bottom: 28px;
			}

			li{
				position:relative;
				margin-bottom:11px;

				a{
					position:relative;
					display:block;
					font-size:16px;
					color:#888;
					transition: .5s ease;
					font-family: 'dosis-medium';

					&:hover {
						color:#8373ce;
						transition: .5s ease;
					}
				}
			}
		}


		.instagram {
			position: relative;
			overflow: hidden;
			margin: 0 -5px;

			.img-box {
				position: relative;
				float: left;
				margin: 0 5px 10px;
			}
		}

		.footer-line {
			@include display-inline-flex(center, space-between);

			.copy-right{
				@include font-p($font-md, $weight-light, 70px, #888);
			}

			.social-links{
				@include display-inline-flex(center, space-between);

				p{
					margin-right: 20px;
				}

				.social {
					text-align: center;

					li{
						display: inline-block;
						margin-right: 10px;

						a{
							@include position;
							width: 24px;
							height: 24px;

							i{
								font-size: 18px;
							}
						}
					}
				}
			}

		}

	}


}

/***

====================================================================
    footer-style-2 style
====================================================================

***/

.footer-style-2 {
    position: relative;
    background: #e3f7ff;
    padding: 105px 0 230px;
	margin-top: 120px;

	@include media-breakpoint-down(sm) {
		.col{
			text-align: center;
		}
	}
}
.footer-style-2 .footer-upper {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1fbff;
}

.footer-style-2:before {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 832px;
    background: url(/front/images/overly/footer-style2.png) center bottom no-repeat;
    z-index: 0;
}

.footer-style-2 .column-title h2 {
    position: relative;
    text-transform: capitalize;
    font-size: 28px;
    color: #ec4f74;
    padding-bottom: 13px;
    margin-bottom: 26px;
}

.footer-style-2 .column-title h2:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 10px;
	background: url(/front/images/icons/sep-3.png) center left no-repeat;
	@include media-breakpoint-down(sm) {
		background-position: center center;
	}

}


.footer-style-2 .footer-widget-about .content p {
    position: relative;
    font-size: 16px;
    color: #888;
    margin-bottom: 24px;
}

.footer-style-2 .footer-widget-about .content ul li {
    position: relative;
    overflow: hidden;
	padding: 12px 0;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}


.footer-style-2 .footer-widget-about .content ul li a {
    position: relative;
    font-size: 15px;
    font-family: 'dosis';
    color: #888;
    float: left;
}

.footer-style-2 .footer-widget-about .content ul li span {
    position: relative;
    margin-right: 20px;
    font-size: 17px;
    float: left;
    top: 2px;
}

/*link-widget*/


.footer-style-2 .news-column {
    padding-bottom: 20px;
}

.footer-style-2 .news-column .news-post{
    position:relative;
    padding:7px 0px 0px 100px;
    margin-bottom:35px;
    color:#888;
    line-height:20px;
}


.footer-style-2 .news-column .news-post a {
    position:relative;
    color:#232323;
    font-size: 18px;
    transition: .5s ease;
}

.footer-style-2 .news-column .news-post .time{
    position:relative;
    color:#888;
    font-size:13px;
    font-family: 'dosis-medium';
    margin-top: 5px;
}

.footer-style-2 .news-column .news-post .image-thumb{
    position:absolute;
    left:0px;
    top:0px;
}

.footer-style-2 .news-column .news-post .image-thumb img{
    width: auto;
}



/*quick link*/
.footer-style-2 .links-column {
	margin-bottom:30px;
	ul{
		list-style-type: none;
		margin-left: 10px;
	}
}
.footer-style-2 .links-column .column-title h2 {
    margin-bottom: 28px;
}

.footer-style-2 .links-column li{
    position:relative;
    margin-bottom:11px;
}

.footer-style-2 .links-column li a{
    position:relative;
    display:block;
    font-size:16px;
    color:#888;
    transition: .5s ease;
    font-family: 'dosis-medium';
}

.footer-style-2 .links-column li a:hover {
    color:#8373ce;
    transition: .5s ease;
}


/*instagram*/

.footer-style-2 .instagram {
    position: relative;
    overflow: hidden;
    margin: 0 -5px;

}

.footer-style-2 .instagram .img-box {
    position: relative;
    float: left;
    margin: 0 5px 10px;
}
/*coppy-right*/
.footer-style-2 .copy-right {
    position: relative;
    line-height: 70px;
    font-size: 14px;
	color: #888;

	@include display-inline-flex(center, space-between);
	@include media-breakpoint-down(md) {
		flex-direction: column;
	}

	.social-links{
		width: 50%;
		@include display-inline-flex(center, flex-end);
		@include media-breakpoint-down(md) {
			width: 100%;
			flex-direction: column;
		}
	}
}

/*socialicon*/



.footer-style-2 .social {
    text-align: center;
}
.footer-style-2 .social,
.footer-style-2 .social-links p {
    display: table-cell;
    padding-right: 16px;
}

.footer-style-2 .social-links p {
    font-size: 16px;
    color: #888;
}

.footer-style-2 .social li {
    display: inline-block;
    margin-right: 11px;
}

.footer-style-2 .social li a {
    color: #888;
    line-height: 30px;
    font-size: 18px;
    transition: .5s ease-in-out;
}

.footer-style-3 .social li a:hover {
    color: #8373ce;
    transition: .5s ease-in-out;
}

/*=====footer-style-3*/

.footer-style-3 {
    position: relative;
    padding: 120px 0 0;
    margin-top: 250px;
    background: #7ac3ac;
}

.footer-style-3:before {
    content: '';
    position: absolute;
    left: 0px;
    top: -200px;
    width: 100%;
    height: 227px;
    background: url(/front/images/overly/footer-style3.png) center top no-repeat;
    z-index: 0;
}


.footer-style-3 .footer-upper {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1fbff;
}



.footer-style-3 .column-title h2 {
    position: relative;
    text-transform: capitalize;
    font-size: 28px;
    color: #fff;
    padding-bottom: 13px;
    margin-bottom: 26px;
}

.footer-style-3 .column-title h2:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 10px;
    background: url(/front/images/icons/sep-2.png) center left no-repeat;
}


.footer-style-3 .footer-widget-about .content p {
    position: relative;
    font-size: 16px;
    color: #fff;
    margin-bottom: 24px;
}

.footer-style-3 .footer-widget-about .content ul li {
    position: relative;
    overflow: hidden;
    padding: 12px 0;
}


.footer-style-3 .footer-widget-about .content ul li a {
    position: relative;
    font-size: 15px;
    font-family: 'dosis';
    color: #fff;
    float: left;
}

.footer-style-3 .footer-widget-about .content ul li span {
    position: relative;
    margin-right: 20px;
    font-size: 17px;
    float: left;
    top: 2px;
    color: #fff;
}

/*link-widget*/


.footer-style-3 .news-column {
    padding-bottom: 20px;
}

.footer-style-3 .news-column .news-post{
    position:relative;
    padding:7px 0px 0px 100px;
    margin-bottom:35px;
    color:#fff;
    line-height:20px;
}


.footer-style-3 .news-column .news-post a {
    position:relative;
    color:#fff;
    font-size: 18px;
    transition: .5s ease;
}

.footer-style-3 .news-column .news-post .time{
    position:relative;
    color:#fff;
    font-size:13px;
    font-family: 'dosis-medium';
    margin-top: 5px;
}

.footer-style-3 .news-column .news-post .image-thumb{
    position:absolute;
    left:0px;
    top:0px;
}

.footer-style-3 .news-column .news-post .image-thumb img{
    width: auto;
}



/*quick link*/
.footer-style-3 .links-column {
    margin-bottom:30px;
}
.footer-style-3 .links-column .column-title h2 {
    margin-bottom: 28px;
}

.footer-style-3 .links-column li{
    position:relative;
    margin-bottom:11px;
}

.footer-style-3 .links-column li a{
    position:relative;
    display:block;
    font-size:16px;
    color:#fff;
    transition: .5s ease;
    font-family: 'dosis-medium';
}

.footer-style-3 .links-column li a:hover {
    color:#232323;
    transition: .5s ease;
}


/*instagram*/

.footer-style-3 .instagram {
    position: relative;
    overflow: hidden;
    margin: 0 -5px;

}

.footer-style-3 .instagram .img-box {
    position: relative;
    float: left;
    margin: 0 5px 10px;
}
/*coppy-right*/
.footer-style-3 .coppy-right {
    position: relative;
    line-height: 70px;
    font-size: 14px;
    color: #fff;
}

/*socialicon*/



.footer-style-3 .social {
    text-align: center;
}
.footer-style-3 .social,
.footer-style-3 .pull-right p {
    display: table-cell;
    padding-right: 16px;
}

.footer-style-3 .pull-right p {
    font-size: 16px;
    color: #fff;
}

.footer-style-3 .social li {
    display: inline-block;
    margin-right: 11px;
}




.footer-style-3 .social li a {
    color: #fff;
    line-height: 30px;
    font-size: 13px;
    transition: .5s ease-in-out;
}

.footer-style-3 .social li a:hover {
    color: #232323;
    transition: .5s ease-in-out;
}