.page-title{
    position: relative;
    padding: 45px 0;
    background-image: url(/front/images/background/2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: url(/front/images/icons/page-title.png) center center no-repeat;
        background-color: rgba(0,0,0,0.40);
        z-index: 0;
    }

    h2{
        position: relative;
        font-size: 50px;
        color: #fff;
        text-transform: capitalize;
        padding: 63px 0;
    }

    ul{
        position: absolute;
        background: #f8b54d;
        padding: 8px 25px;
        bottom: -30px;
        border-radius: 0 15px;
        z-index: 9;

        li{
            display: inline-block;
            @include position;

            &:after {
                position: absolute;
                content: '/';
                font-size: 20px;
                font-family: 'bubblegum';
                color: #fff;
                right: 0px;
                top: 9px;
            }

            &:last-child:after {
                display: none;
            }

            a {
                position: relative;
                font-size: 20px;
                padding: 7px 0;
                display: inline-block;
                text-transform: capitalize;
                color: #fff;
                transition: .5s ease;

                &:hover {
                    color: #232323;
                    transition: .5s ease;
                }

                &:first-child {
                    margin-right: 17px;
                }
            }
        }
    }
}

/*----------  Scroll To Top  ----------*/
.faculty {
    position: relative;
    padding-top: 120px;

    .team-list {
        padding-bottom: 85px;
    }

    &.style-2 .team-list {
        border-bottom: 0px;
    }

    .item {
        position: relative;
        margin-bottom: 30px;

        img {
            width: 100%;
            border-radius: 0 15px 0 15px;
        }

        .content {
            position: relative;
            text-align: left;
            padding: 20px 20px;
            padding-right: 65px;
            border: 1px solid #ececec;
        }
    }
}

/** Page with sidebar **/

.sidebar-page {
    padding: 120px 0;

    .img-box-s2 {
        position: relative;
        margin-bottom: 50px;

        img {
            width: 100%;
        }

        .outer-box {
            border: 1px solid #ececec;
            border-top: 0px;
            border-radius: 0 0 0 15px;
        }

        .content {
            padding: 45px 45px;
            padding-top: 30px;

            .date {
                position: relative;
                color: #f8b54d;
                font-size: 15px;
                font-family: 'dosis';
                margin-bottom: 10px;
                padding-top: 5px;

                span {
                    margin-right: 20px;
                }
            }

            h3 {
                position: relative;
                color: #77cabd;
                font-size: 24px;
                margin-bottom: 10px;

                a {
                    color: #8a7bd1;
                    transition: .5s ease;

                    &:hover {
                        color: #232323;
                        transition: .5s ease;
                    }
                }
            }
        }

        &.color-style2{

            .outer-box {
                border-color: #ececec;
            }

            .content{
                .date {
                    color: #adca69;
                }

                h3{
                    a {
                        color: #adca69;
                    }
                }
            }
        }

        .text p {
            font-size: 17px;
            line-height: 26px;
        }

        .list {
            margin-top: 18px;

            li {
                position:relative;
                padding:10px 0px 10px 22px;
                display:block;
                color:#777;
                font-size:16px;
                font-family: 'dosis';
                transition: .5s ease;

                &:after{
                    position:absolute;
                    content: "";
                    left:3px;
                    top:20px;
                    width:6px;
                    height:6px;
                    line-height:32px;
                    display:block;
                    background:#f8b54d;
                }
            }
        }
    }
}

.sidebar{
    .catagories.list.product-info li:after {
        display: none;
    }


    .catagories.list.product-info li {
        position: relative;
        line-height: 32px;
        padding: 10px 0px 10px 20px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 15px;
        color: #232323;
    }
    .catagories.list.product-info li:last-child {
        border-bottom: 0px;
    }

    .catagories.list.product-info li span {
        color: #777;
    }

    .catagories.list.product-info li i {
        color: #888;
        font-size: 20px;
        position: relative;
        top: 4px;
        left: -15px;
    }

    .map-section {
        border-radius: 0 15px;
        overflow: hidden;
        height: 270px;
    }

    .map-section .gmnoprint.gm-bundled-control {
        display: none;
    }

    .map-section .gmnoprint {
        display: none;
    }

    .map-section .gm-style-cc {
        display: none;
    }
}


/***
====================================================================
                            faculties Section
====================================================================
***/
.faculty {
    position: relative;
    padding-top: 120px;
    .team-list {
        padding-bottom: 85px;
    }

    .item {
        position: relative;
        margin-bottom: 30px;

        img {
            width: 100%;
            border-radius: 0 15px 0 15px;
        }

        .content {
            position: relative;
            text-align: left;
            padding: 20px 20px;
            padding-right: 65px;
            border: 1px solid #ececec;
        }

    }
}

.faculty.style-2 .team-list {
    border-bottom: 0px;
}


/***

====================================================================
    about style
====================================================================

***/

.about {
    position: relative;
    padding: 50px 0;

    .section-title {
        padding-top: 14px;
        padding-bottom: 20px;
    }

    .post-content{
        .text {
            margin-bottom: 10px;

            p{
                span {
                    color: #222222 ;
                }
            }
        }

        .link {
            overflow: hidden;
            padding-top: 12px;

            a {
                position: relative;
                color: #8ec4dc;
                font-size: 20px;
                border-bottom: 1px dotted #8ec4dc;
                margin-right: 20px;
            }
        }
    }

    .list {
        position: relative;
        margin-top: 10px;

        li {
            position: relative;
            font-family: "dosis";
            color: #777;
            font-size: 18px;
            padding-left: 38px;
            padding-bottom: 8px;

            &:before {
                position:absolute;
                content: "";
                left:0px;
                top:6px;
                width:15px;
                height:15px;
                background: #f8ba59;
                border-radius: 50%;
            }

            &:after {
                position:absolute;
                content: "";
                left:3px;
                top:9px;
                width:9px;
                height:9px;
                border: 2px solid #fff;
                border-radius: 50%;
            }
        }
    }

    .img-box{
        img {
            width: 100%;
            border-radius: 0 15px 0 15px;
        }
    }
}


/*style-2*/

.style-2.about {
    padding: 120px 0 0;

}
.style-2.about .post-content .text p span {
    color: #fd5b4e
}


.style-2.about .img-box img {
    width: auto;
    border-radius: 0px;
    position: relative;
    margin-left: -82px;
}

/*style-3*/


.about .chose-info .item {
    position: relative;
    padding-left: 53px;
    margin-bottom: 30px;
    padding-top: 8px;
}


.about .chose-info  .item .icon {
    position:absolute;
    top: 0;
    left: 0;
    background: #f8b54d;
    height: 35px;
    width: 35px;
    border-radius: 0 5px;
    text-align: center;
    transition: .5s ease-in;
}


.about .chose-info  .item .icon span:before {
    position: relative;
    color: #fff;
    line-height: 39px;
    font-size: 20px;
}


.about .chose-info  .item h4 {
    margin-bottom:16px;

}

.about .chose-info  .item h4 a {
    position:relative;
    font-size: 18px;
    font-family: 'dosis';
    transition: .5s ease;
}


.about .chose-info  .item h4 a:hover {
    color: inherit;
}

.about .post-content .text {
    margin-bottom: 55px;
}



/**********************/
/*** Admission Form ***/
/**********************/
.inquiry-form label.error{
    color: red;
    font-size: 14px;
    font-family: 'dosis';
}

.programm-list-wrapper ul li{
    width: 50%;
    float: left;
}

/** Home Page News/Event Scroller Styles **/
.home-page-news{

    padding: 120px 0;

    .box{
        background-color: #e0e0e0;
        padding: 50px;
        border-radius: 10px;

        .box-item{
            display: flex;
            flex-direction: row;
            align-items: center;
			margin: 20px 0;

			.info-wrapper{
				margin-left: 20px;
			}

            a{
                &.item-link{
                    color: #8a7bd1;
                    font-size: 24px;
                    font-family: 'bubblegum';
                }

                img{
                    width: 100px;
                    height: 100px;
                    border: 3px solid #f8b54d;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        }
    }
}

.news-marquee-container {
    height: 320px;
    overflow: hidden;

    .marquee {
        top: 20em;
        position: relative;
        box-sizing: border-box;
        animation: marquee 10s linear infinite;

        &:hover {
            animation-play-state: paused
        }
    }
}

@keyframes marquee {
    0% {
        top: 320px
    }

    100% {
        top: -400px
    }
}


.marquee-scroll {
	width: 450px;
	margin: 0 auto;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
  }

  .marquee-scroll span {
	display: inline-block;
	padding-left: 100%;
	will-change: transform;
	/* show the marquee just outside the paragraph */
	animation: marquee-lr 15s linear infinite;
  }

  .marquee-scroll span:hover {
	animation-play-state: paused
  }


  /* Make it move */

  @keyframes marquee-lr {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(-100%, 0);
	}
  }


/** Sidebar Page **/
.sidebar-page.blog .img-box-s2 {
	position: relative;
	margin-bottom: 50px
}

.sidebar-page.blog .img-box-s2 .content .date {
	color: #f8b54d
}

.sidebar-page.blog .img-box-s2 .content .date span {
	margin-right: 20px
}

.sidebar-page.blog .img-box-s2 .content h3 a {
	color: #8a7bd1
}

.sidebar-page.blog .img-box-s2 .outer-box {
	border: 1px solid #ececec
}

.sidebar-page.blog .img-box-s2 .content {
	padding: 45px 45px;
	padding-top: 30px
}

.blog .img-box-s2 .content .quote h4 {
	position: relative;
	color: #f8b54d;
	font-size: 19px;
	margin-bottom: 10px;
	margin-top: 10px
}

.blog .img-box-s2 .content .quote p {
	position: relative;
	padding: 30px 40px
}

.blog .img-box-s2 .content .quote p span {
	position: absolute;
	font-size: 150px;
	font-family: "Vani";
	top: 90px;
	left: -12px;
	color: #777;
	opacity: .15
}

.single-post .comments {
	position: relative;
	padding-bottom: 20px
}

.single-post .comment-title h2 {
	position: relative;
	font-size: 33px;
	color: #8a7bd1;
	padding-bottom: 40px
}

.single-post .comment-title h2 span {
	color: #f8b54d
}

.single-post .comments .single-comment {
	position: relative;
	padding-left: 117px;
	margin-bottom: 40px
}

.single-post .comments .single-comment.comment-reply {
	margin-left: 127px
}

.single-post .comments .single-comment .inner-box {
	position: relative;
	border: 1px solid #ececec;
	padding: 24px 29px;
	min-height: 167px
}

.single-post .comments .single-comment.comment-reply .inner-box {
	min-height: 139px
}

.single-post .comments .single-comment .inner-box .curve {
	position: absolute;
	left: -10px;
	top: 21px;
	display: block;
	width: 10px;
	height: 20px;
	overflow: hidden;
	z-index: 5
}

.single-post .comments .single-comment .inner-box .curve:before {
	content: '';
	position: absolute;
	left: 4px;
	top: 0;
	display: block;
	width: 20px;
	height: 20px;
	border: 1px solid #ececec;
	background: #fff;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg)
}

.single-post .comments .single-comment .author {
	position: absolute;
	left: 0;
	top: 6px
}

.single-post .comments .single-comment .author img {
	position: relative
}

.single-post .comments .single-comment .author-info {
	position: relative
}

.single-post .comments .single-comment .author-info .author-title {
	position: relative;
	font-size: 20px;
	color: #77c9bc;
	font-family: 'bubblegum';
	margin-right: 20px;
	line-height: 17px;
	margin-bottom: 23px
}

.single-post .comments .single-comment .author-info .date {
	position: relative;
	font-size: 16px;
	color: #f8b54d;
	font-family: 'dosis';
	margin-right: 37px
}

.single-post .comments .single-comment .text {
	position: relative
}

.single-post .comments .single-comment .text p {
	margin-bottom: 30px
}

.single-post .comments .single-comment .reply-info {
	position: absolute;
	right: 18px;
	bottom: 22px
}

.single-post .comments .single-comment .reply-info ul {
	position: relative
}

.single-post .comments .single-comment .reply-info ul li {
	position: relative;
	display: inline-block;
	margin-right: 10px;
	font-size: 15px;
	font-family: 'dosis';
	text-transform: capitalize
}

.single-post .comments .single-comment .reply-info ul li a {
	position: relative;
	transition: .5s ease;
	color: #777
}

.single-post .comments .single-comment .reply-info ul li a:hover {
	color: #f8b54d;
	transition: .5s ease
}

.single-post .comment-form {
	position: relative
}

.single-post .comment-form .form-group {
	position: relative;
	margin-bottom: 30px
}

.single-post .comment-form .form-group input[type="text"],
.single-post .comment-form .form-group input[type="password"],
.single-post .comment-form .form-group input[type="tel"],
.single-post .comment-form .form-group input[type="email"],
.single-post .comment-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	padding: 0 30px;
	color: #777;
	font-size: 14px;
	border-radius: 0 15px;
	font-family: 'dosis';
	border: 1px solid #ececec;
	height: 50px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease
}

.single-post .comment-form .form-group input[type="text"]:focus,
.single-post .comment-form .form-group input[type="password"]:focus,
.single-post .comment-form .form-group input[type="tel"]:focus,
.single-post .comment-form .form-group input[type="email"]:focus,
.single-post .comment-form .form-group select:focus,
.single-post .comment-form .form-group textarea:focus {
	border-color: #19a4c6
}

.single-post .comment-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 22px;
	padding: 25px 30px;
	border-radius: 0 15px;
	color: #999;
	font-family: 'dosis';
	font-size: 14px;
	border: 1px solid #ececec;
	height: 170px;
	resize: none;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease
}

.single-post .comment-form .form-group input.error,
.single-post .comment-form .form-group textarea.error,
.single-post .comment-form .form-group select.error {
	border-color: #19a4c6
}

.single-post .single-post .comment-form .form-group input.error:focus,
.single-post .single-post .comment-form .form-group textarea.error:focus {
	border-color: #19a4c6;
	box-shadow: 0 0 3px 0 #19a4c6;
	-moz-box-shadow: 0 0 3px 0 #19a4c6;
	-ms-box-shadow: 0 0 3px 0 #19a4c6;
	-o-box-shadow: 0 0 3px 0 #19a4c6;
	-webkit-box-shadow: 0 0 3px 0 #19a4c6
}

.single-post .comment-form .contact-form label.error {
	display: none!important
}

.single-post .comment-form button {
	font-size: 20px;
	font-family: 'bubblegum';
	text-transform: uppercase;
	padding: 11px 36px
}

.sidebar-page .sidebar {
	position: relative;
	padding: 0 0 0
}

.sidebar .widget {
	position: relative;
	margin-bottom: 40px;
	border: 1px solid #ececec;
	padding: 35px 30px;
	border-radius: 0 10px
}

.sidebar .sidebar-title {
	position: relative;
	padding: 10px 0
}

.sidebar .sidebar-title h2 {
	position: relative;
	font-size: 25px;
	color: #8a7bd1;
	padding-bottom: 23px
}

.sidebar .sidebar-title h2 span {
	color: #f8b54d
}

.sidebar .recent-posts .post {
	position: relative;
	margin-bottom: 25px;
	min-height: 105px;
	padding: 0 0 24px 100px;
	border-bottom: 1px solid #eaeaea
}

.sidebar .recent-posts .post:last-child {
	border-bottom: 0;
	margin-bottom: 0
}

.sidebar .recent-posts .post .post-thumb {
	position: absolute;
	left: 0;
	top: 0;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden
}

.sidebar .recent-posts .post .post-thumb img {
	width: 100%;
	display: block
}

.sidebar .recent-posts .post h4 {
	position: relative;
	top: 8px;
	margin: 0 0 7px;
	line-height: 20px;
	color: #232323
}

.sidebar .recent-posts .post .post-info {
	font-size: 15px;
	margin: 0;
	line-height: 1.6em;
	color: #777;
	font-family: 'dosis'
}

.sidebar .recent-posts .post a,
.sidebar .recent-posts .post a:hover {
	color: #ff9a00;
	transition: .5s ease
}

.sidebar .recent-posts .post h4 a {
	color: #333;
	transition: .5s ease;
	font-size: 16px;
	font-family: 'dosis-medium'
}

.sidebar .recent-posts .post .fa {
	font-size: 18px
}

.sidebar-page .contact-info {
	position: relative;
	font-size: 14px;
	margin-bottom: 30px
}

.sidebar-page .contact-info .text {
	position: relative;
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 10px;
	color: #7f7f7f
}

.sidebar-page .contact-info li {
	position: relative;
	margin-bottom: 5px;
	line-height: 1.8em;
	color: #7f7f7f
}

.sidebar-page .contact-info li strong {
	position: relative;
	padding-right: 10px;
	font-weight: 600;
	color: #292929
}

.sidebar-page .contact-info a {
	color: #f17338;
	transition: all 300ms ease-in;
	-webkit-transition: all 300ms ease-in;
	-ms-transition: all 300ms ease-in;
	-o-transition: all 300ms ease-in;
	-moz-transition: all 300ms ease-in
}

.sidebar-page .contact-info a:hover {
	color: #3d3d3d
}

.recent-photo .instagram .img-box {
	position: relative;
	float: left;
	margin: 0 2.5px 5px
}

.sidebar .popular-tags a {
	position: relative;
	display: inline-block;
	line-height: 24px;
	padding: 5px 21px;
	background: #f7f7f7;
	color: #777;
	margin: 0 2px 5px 0;
	text-transform: capitalize;
	font-size: 14px;
	font-family: 'dosis';
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease
}

.sidebar .popular-tags a:hover {
	background-color: #ff9a00;
	color: #fff;
	transition: .5s ease
}

.sidebar .single-post,
.sidebar .list.catagories {
	position: relative
}
.sidebar .list{
    list-style-type: none;

    &.no-bullet{
        li{
            &:before{
                content: none;
            }
        }
    }
}
.sidebar .list li {
	position: relative;
	line-height: 32px;
	padding: 10px 0 10px 0;
    border-bottom: 1px solid #f2f2f2;
}

.sidebar .list li:last-child {
	border-bottom: 0
}

.sidebar .catagories.list li:after {
	position: absolute;
	content: "";
	left: 3px;
	top: 24px;
	width: 5px;
	height: 5px;
	line-height: 32px;
	display: block;
	background: #8a7bd1
}

.sidebar .catagories.list li a {
	padding-left: 22px
}

.sidebar .list li a {
	position: relative;
	display: block;
	color: #777;
    font-size: 16px;
    line-height: 32px;
	font-family: 'dosis';
	transition: .5s ease
}

.sidebar .list li a:hover {
	color: #ff9a00;
	transition: .5s ease
}

.sidebar .recent-gallery {
	position: relative
}

.sidebar .recent-gallery .image {
	position: relative;
	float: left;
	width: 33.333%;
	padding: 0 10px 10px 0
}

.sidebar .recent-gallery .image img {
	position: relative;
	display: block;
	width: 100%
}




/***

====================================================================
    blog section
====================================================================

***/


.blog {
    position: relative;
    padding: 120px 0;
}

.blog .img-box-s1 {
    position: relative;
    margin-bottom: 30px;
    border-radius: 0 15px;
    overflow: hidden;
}

.blog .img-box-s1 img {
    width: 100%;
}

.blog .img-box-s1 .overlay {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0;
    padding: 15px 32px;
    background: transparent;
    background: -webkit-linear-gradient(to top, rgba(35, 29, 29, 0.72), rgba(66, 66, 55, 0));
    background: -o-linear-gradient(to top, rgba(35, 29, 29, 0.72), rgba(66, 66, 55, 0));
    background: -moz-linear-gradient(to top, rgba(35, 29, 29, 0.72), rgba(66, 66, 55, 0));
    background: linear-gradient(to top, rgba(35, 29, 29, 0.72), rgba(66, 66, 55, 0));
}
.blog .img-box-s1 .overlay .date {
    position: relative;
    color: #fff;
    font-size: 15px;
    font-family: 'dosis';
    margin-bottom: 5px;
}
.blog .img-box-s1 .overlay h3 {
    position: relative;
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
}
.blog .img-box-s1 .overlay h3 a {
    color: #fff;
}

.blog .img-box-s1 .overlay img {
    width: auto;
    border-radius: 50%;
    margin-right: 15px;
}

.blog .img-box-s1 .overlay .comment,
.blog .img-box-s1 .overlay .author {
    display: table-cell;
    color: #fff;
    font-size: 15px;
    font-family: 'dosis';
    padding-right: 12px;
}

.blog .img-box-s2 {
    position: relative;
    margin-bottom: 30px;
}

.blog .img-box-s2 a{
    width: 100%;
}

.blog .img-box-s2 img {
    width: 100%;
}

.blog .img-box-s2 .outer-box {
    border: 1px solid #77cabd;
    border-top: 0px;
    border-radius: 0 0 0 15px;
}
.blog .img-box-s2.color-style2 .outer-box {
    border-color: #adca69;
}

.blog .img-box-s2 .content {
    position: relative;
    padding: 25px 32px;
    border-bottom: 1px solid #deeac3;
}

.blog .img-box-s2 .content .date {
    position: relative;
    color: #77cabd;
    font-size: 15px;
    font-family: 'dosis';
    margin-bottom: 5px;
}
.blog .img-box-s2.color-style2 .content .date {
    color: #adca69;
}

.blog .img-box-s2 .content h3 {
    position: relative;
    color: #77cabd;
    font-size: 24px;
    margin-bottom: 10px;
}
.blog .img-box-s2 .content h3 a {
    color: #77cabd;
}

.blog .img-box-s2.color-style2 .content h3 a {
    color: #adca69;
}

.blog .img-box-s2 .text p {
    font-size: 17px;
    line-height: 26px;
}
.blog .img-box-s2 .author img {
    width: auto;
    border-radius: 50%;
    margin-right: 15px;
}

.blog .img-box-s2 .comment,
.blog .img-box-s2 .author {
    position: relative;
    color: #777;
    font-size: 15px;
    font-family: 'dosis';
    padding-right: 12px;
    line-height: 34px;
}


.blog .img-box-s2 .comment-area {
    padding: 13px 30px;
    overflow: hidden;

    a{
        @include font-s($font-sm, $weight-regular, $font-sm, $brand-gray-01);
    }
}


.blog .quot {
    position: relative;
    background: #f8b54d;
    color: #fff;
    padding: 36px 30px 45px;
    border-radius: 0 15px;
    margin-bottom: 30px;
}

.blog .quot .icon {
    position: relative;
    line-height: 50px;
    margin-bottom: 10px;
}

.blog .quot h3 {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;

}

.blog .quot h4 {
    position: relative;
    text-align: right;
    color: #fff;
    font-size: 16px;
    margin-right: 13px;
}

.blog .quot h4 a {
    color: #fff;
}

.blog .readmore {
    position: relative;
    padding-top: 30px;
}

.blog .readmore .link {
    position: relative;
    color: #ff8454;
    font-size: 18px;
    border-bottom: 1px dashed #ff8454;
    padding-bottom: 5px;
}


/* ==============================
   Cart Page styles
   ============================== */
.cart-section {
	position: relative;
	padding: 80px 0px;
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
		}
	}
	.thm-btn {
		font-size: 16px;
		font-family: 'dosis-bold';
		padding: 12px 22px;
		min-width: 130px;
	}
	.cart-outer {
		position: relative;
	}
	.table-outer {
		position: relative;
		width: 100%;
		overflow-x: auto;
	}
	.cart-table {
		width: 100%;
		min-width: 900px;
	}
	.apply-coupon {
		position: relative;
		.form-group {
			position: relative;
			float: left;
			margin-right: 20px;
			input[type="text"] {
				display: block;
				line-height: 24px;
				padding: 9px 15px;
				border: 1px solid #e0e0e0;
				border-radius: 0 10px;
				width: 230px;
				background: none;
				font-weight: 500;
				outline: none;
				height: 50px;
			}
		}
	}
	.estimate-form {
		position: relative;
		.row {
			margin-left: -7px;
			margin-right: -7px;
			.form-group {
				padding: 0px 7px !important;
				margin-bottom: 20px;
			}
		}
		input {
			width: 100%;
			border: 2px solid #F8F8F8;
			outline: none;
			height: 45px;
			padding-left: 15px;
		}
		select {
			width: 100%;
			border: 2px solid #F8F8F8;
			outline: none;
			height: 45px;
			padding-left: 15px;
			color: #9E9E9E;
			font-size: 16px;
			-webkit-appearance: none;
			-ms-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;
			background: #ffffff url(/front/images/resources/icon-dropdown.png) 97% center no-repeat;
			cursor: pointer;
		}
	}
	h3 {
		font-size: 24px;
		font-weight: 700;
		color: #2f2f31;
		margin-bottom: 30px;
	}
	.totals-table {
		position: relative;
		.col {
			position: relative;
			display: block;
			float: left;
			padding: 10px 15px;
			line-height: 24px;
			width: 50%;
			color: #777;
		}
		.col-title {
			font-family: 'dosis-semibold';
			font-size: 16px;
			color: #777;
		}
		.total {
			.col {
				color: #f8b54d;
			}
			.col-title {
				color: #f8b54d;
			}
		}
		li {
			position: relative;
			border-bottom: 1px solid #ececec;
			&:last-child {
				border-bottom: 0px;
			}
		}
	}
	.update-cart-box {
		padding-top: 40px;
	}
	.cart-total {
		h3 {
			font-size: 20px;
			color: #8a7bd1;
			margin: 0;
			margin-bottom: 40px;
			margin-top: 80px;
		}
		.thm-btn {
			margin-top: 30px;
			width: 100%;
			text-align: center;
			background: #8a7bd1;
			font-size: 18px;
			font-family: 'bubblegum';
			span {
				font-size: 18px;
				position: relative;
				top: 2px;
				left: 6px;
			}
		}
	}
	.thm-btn.update-cart {
		color: #fff;
		min-width: 170px;
	}
	.bootstrap-touchspin {
		.input-group-btn-vertical {
			>.btn {
				padding: 12px 11.5px;
			}
			i {
				top: 6px;
				left: 7px;
			}
		}
	}
}
.cart-table {
	.cart-header {
		position: relative;
		width: 100%;
		font-family: 'bubblegum';
		text-transform: capitalize;
		font-size: 20px;
		border-radius: 7px;
		background: #f8b54d;
		color: #fff;
		.remove {
			span {
				&:before {
					color: #fff;
				}
			}
		}
	}
	thead {
		tr {
			th {
				line-height: 24px;
				padding: 20px 15px;
				min-width: 120px;
				text-align: center;
			}
			th.prod-column {
				text-align: left;
				padding-left: 40px;
			}
		}
	}
	tbody {
		tr {
			td {
				line-height: 24px;
				padding: 20px 20px;
				min-width: 100px;
				font-family: 'dosis-semibold';
				font-size: 18px;
				color: #fba505;
				text-align: center;
				vertical-align: middle;
				.quantity-spinner {
					padding: 5px 0px 5px 20px;
					line-height: 24px;
					height: 50px;
					display: block;
					width: 100%;
					position: relative;
				}
			}
			.qty {
				width: 120px;
				padding-right: 20px;
				.quantity-spinner {
					background: #f5f5f5;
				}
			}
			.prod-column {
				.column-box {
					position: relative;
					min-height: 90px;
					padding-left: 140px;
					padding-top: 15px;
					text-align: left;
					.prod-thumb {
						position: absolute;
						left: 0px;
						top: -5px;
						background: #F4F4F4;
						img {
							display: block;
							max-width: 100%;
						}
					}
					h3 {
						font-size: 18px;
						margin: 0;
						margin-top: 20px;
						margin-bottom: 5px;
						color: #8a7bd1;
						font-family: 'dosis-semibold';
						text-transform: capitalize;
					}
				}
			}
			.remove-btn {
				position: relative;
				font-size: 16px;
				color: #282828;
				line-height: 30px;
				-webkit-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				transition: all 500ms ease;
				.fa {
					position: relative;
					top: 2px;
					padding-right: 10px;
					font-size: 15px;
					line-height: 30px;
					color: #999;
					transition: .5s ease;
					&:hover {
						color: #f8b54d;
						transition: .5s ease;
					}
				}
				&:hover {
					color: #f8b54d;
				}
			}
			border-bottom: 1px solid #e9e9e9;
		}
		.available-info {
			position: relative;
			padding-left: 50px;
			color: #9E9E9E;
			font-size: 16px;
			.icon {
				position: absolute;
				left: 0px;
				top: 5px;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				font-size: 18px;
				color: #ffffff;
				background: #012f5d;
				border-radius: 50%;
			}
		}
	}
}


/** Contact Us page **/

.contact-us {
	position: relative;
	padding: 120px 0 50px;
	.section-title {
		margin-bottom: 40px;
	}
	.upper {
		.item {
			position: relative;
			margin-bottom: 64px;
			.icon {
				position: relative;
				height: 90px;
				color: #fff;
				width: 90px;
				margin: 0 auto;
				border-radius: 0 15px 0 15px;
				text-align: center;
				transition: .5s ease-in;
				margin-bottom: 20px;
				span {
					&:before {
						position: relative;
						line-height: 90px;
						font-size: 30px;
					}
				}
			}
			h4 {
				margin-bottom: 16px;
				font-size: 25px;
				a {
					position: relative;
					transition: .5s ease;
				}
			}
			p {
				position: relative;
				color: #777;
				font-size: 17px;
				line-height: 26px;
			}
		}
		.column {
			&:nth-child(1) {
				.item {
					.icon {
						background: #adcb69;
						border: 2px solid #adcb69;
					}
					&:hover {
						.icon {
							background: transparent;
							color: #adcb69;
							transition: .5s ease-in;
						}
					}
				}
				h4 {
					a {
						color: #adcb69;
					}
				}
			}
			&:nth-child(2) {
				.item {
					.icon {
						background: #ec4f74;
						border: 2px solid #ec4f74;
					}
					&:hover {
						.icon {
							background: transparent;
							color: #ec4f74;
							transition: .5s ease-in;
						}
					}
				}
				h4 {
					a {
						color: #ec4f74;
					}
				}
			}
			&:nth-child(3) {
				.item {
					.icon {
						background: #5bc89f;
						border: 2px solid #5bc89f;
					}
					&:hover {
						.icon {
							background: transparent;
							color: #5bc89f;
							transition: .5s ease-in;
						}
					}
				}
				h4 {
					a {
						color: #5bc89f;
					}
				}
			}
		}
	}
	.contact-box {
		position: relative;
		margin-bottom: 100px;
		.contact-form {
			margin-top: 6px;
		}
		.form-group {
			position: relative;
			margin-bottom: 25px;
			overflow: hidden;
		}
		input[type="text"] {
			position: relative;
			width: 100%;
			line-height: 18px;
			font-size: 14px;
			font-family: 'Open sans', sans-serif;
			font-weight: bold;
			color: #777;
			background: #fafafa;
			border: 1px solid #ececec;
			border-radius: 3px;
			height: 60px;
			padding: 18px 36px;
			transition: all 500ms ease;
			-moz-transition: all 500ms ease;
			-webkit-transition: all 500ms ease;
			-ms-transition: all 500ms ease;
			-o-transition: all 500ms ease;
		}
		input[type="email"] {
			position: relative;
			width: 100%;
			line-height: 18px;
			font-size: 14px;
			font-family: 'Open sans', sans-serif;
			font-weight: bold;
			color: #777;
			background: #fafafa;
			border: 1px solid #ececec;
			border-radius: 3px;
			height: 60px;
			padding: 18px 36px;
			transition: all 500ms ease;
			-moz-transition: all 500ms ease;
			-webkit-transition: all 500ms ease;
			-ms-transition: all 500ms ease;
			-o-transition: all 500ms ease;
		}
		input[type="password"] {
			position: relative;
			width: 100%;
			line-height: 18px;
			font-size: 14px;
			font-family: 'Open sans', sans-serif;
			font-weight: bold;
			color: #777;
			background: #fafafa;
			border: 1px solid #ececec;
			border-radius: 3px;
			height: 60px;
			padding: 18px 36px;
			transition: all 500ms ease;
			-moz-transition: all 500ms ease;
			-webkit-transition: all 500ms ease;
			-ms-transition: all 500ms ease;
			-o-transition: all 500ms ease;
		}
		input[type="date"] {
			position: relative;
			width: 100%;
			line-height: 18px;
			font-size: 14px;
			font-family: 'Open sans', sans-serif;
			font-weight: bold;
			color: #777;
			background: #fafafa;
			border: 1px solid #ececec;
			border-radius: 3px;
			height: 60px;
			padding: 18px 36px;
			transition: all 500ms ease;
			-moz-transition: all 500ms ease;
			-webkit-transition: all 500ms ease;
			-ms-transition: all 500ms ease;
			-o-transition: all 500ms ease;
		}
		select {
			position: relative;
			width: 100%;
			line-height: 18px;
			font-size: 14px;
			font-family: 'Open sans', sans-serif;
			font-weight: bold;
			color: #777;
			background: #fafafa;
			border: 1px solid #ececec;
			border-radius: 3px;
			height: 60px;
			padding: 18px 36px;
			transition: all 500ms ease;
			-moz-transition: all 500ms ease;
			-webkit-transition: all 500ms ease;
			-ms-transition: all 500ms ease;
			-o-transition: all 500ms ease;
			&:focus {
				border-color: #ff9a00;
			}
			option {
				padding: 5px 15px;
			}
		}
		textarea {
			position: relative;
			width: 100%;
			line-height: 24px;
			padding: 20px 36px;
			height: 180px;
			resize: none;
			background: #fafafa;
			border: 1px solid #ececec;
			font-family: 'Open sans', sans-serif;
			font-size: 14px;
			color: #777;
			border-radius: 3px;
			padding-bottom: 0px;
			margin-bottom: 0px;
			transition: all 500ms ease;
			-moz-transition: all 500ms ease;
			-webkit-transition: all 500ms ease;
			-ms-transition: all 500ms ease;
			-o-transition: all 500ms ease;
			&:focus {
				border-color: #ff9a00;
			}
		}
		input {
			&:focus {
				border-color: #ff9a00;
			}
		}
		input.error {
			border-color: #ff9a00;
			&:focus {
				border-color: #ff9a00;
				box-shadow: 0px 0px 3px 0px #ff9a00;
				-moz-box-shadow: 0px 0px 3px 0px #ff9a00;
				-ms-box-shadow: 0px 0px 3px 0px #ff9a00;
				-o-box-shadow: 0px 0px 3px 0px #ff9a00;
				-webkit-box-shadow: 0px 0px 3px 0px #ff9a00;
			}
		}
		textarea.error {
			border-color: #ff9a00;
			&:focus {
				border-color: #ff9a00;
				box-shadow: 0px 0px 3px 0px #ff9a00;
				-moz-box-shadow: 0px 0px 3px 0px #ff9a00;
				-ms-box-shadow: 0px 0px 3px 0px #ff9a00;
				-o-box-shadow: 0px 0px 3px 0px #ff9a00;
				-webkit-box-shadow: 0px 0px 3px 0px #ff9a00;
			}
		}
		select.error {
			border-color: #ff9a00;
		}
		label.error {
			font-size: 12px;
			color: red;
		}
		button {
			background: #ff9a00;
			color: #fff;
			line-height: 45px;
			padding: 0 41px;
			font-family: 'bubblegum';
			text-transform: capitalize;
			&:hover {
				background: transparent !important;
				color: #ff9a00;
				border-color: #ff9a00;
				transition: .5s ease;
			}
		}
	}
}
