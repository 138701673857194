/*----------  Body  ----------*/

body {
    //@include font-s($font-md, $weight-regular, $font-md, $brand-gray-01);
    overflow-x: hidden;

    &.overflow-hidden {
        overflow: hidden;
	}
}

.hidden{
    display: none;
}



ul.list, ul.list-icon {
    margin-top: 10px;

    li{
        @include font-s($font-md, $weight-regular, $font-md + rem-calc(10), $brand-gray-01);
        position: relative;
        list-style-type: none;
        padding-left: 38px;
        padding-bottom: 8px;

        &:before{
            position: absolute;
            content: "";
            left: 0px;
            top: 6px;
            width: 15px;
            height: 15px;
            background: #f8ba59;
            border-radius: 50%;
        }

        &:after{
            position: absolute;
            content: "";
            left: 3px;
            top: 9px;
            width: 9px;
            height: 9px;
            border: 2px solid #fff;
            border-radius: 50%;
        }

    }
}

/*----------  Theme Buttons  ----------*/
.btn-style-one {
    @include font-p($font-lg, $weight-regular, $font-lg + rem-calc(2), $white-color);
    border: 2px solid transparent;
    text-transform: capitalize;
    background: $color-04;
    display: inline-block;
    min-width: 170px;
    padding: 14px 36px;
    border-radius: 0 15px 0 15px;
    position: relative;
    text-align: center;
    @include transition(.5s ease-in);


    &:hover{
        background: rgba(0, 0, 0, 0.84) !important;
        color: $white-color;
    }
}


/*----------  Custom Classes  ----------*/
.text{
    margin-bottom: 55px;
}

.link{
    padding-top: 12px;
    a{
        @include font-p($font-lg, $weight-regular, $font-lg + rem-calc(2), $color-02);
        border-bottom: 1px dotted $color-02;
        margin-right: 20px;
    }
}
.center{
    text-align: center;
}
.fixed{
    @include position(fixed);
}

/*----------  Call-out  ----------*/
.call-out{
    padding: 100px 0;
    color: $white-color;
    background: $color-03;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }

    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: url(/front/images/overly/pattern.png) center center no-repeat;
        background-size: cover;
    }

    .call-out-inner{
        @include display-inline-flex;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    h2{
        @include font-p($font-xxl, $weight-regular, $font-xxl + rem-calc(2), $white-color);
        margin-right: 80px;
        @include media-breakpoint-down(md) {
            margin: 10px;
        }
    }

    p{
        @include font-p($font-lg, $weight-regular, $font-lg + rem-calc(2), $white-color);
        display: inline-block;
        @include flex-grow(4);
        @include media-breakpoint-down(md) {
            margin: 10px;
            text-align: center;
        }
    }
}

/*----------  Slick-Slider  ----------*/
.slider-inner-content{
    position: relative;
    margin-top: 50px;
}
.slick-nav-container{
    text-align: right;
    position: absolute;
    top: -50px;
    right: 0;
    display: inline-block;
    z-index: 1;

    a{
        &.slick-nav{
            color: $color-02;
            font-size: 20px;
            margin: 0;
            padding: 0;
            border: 1px solid $color-02;
            background-color: transparent;
            margin: 0 5px;
            padding: 10px 14px;
            display: inline-block;
            @include transition;


            &:hover{
                background-color: $brand-primary;
                color: $white-color;
            }
        }

        &.next{
            border-radius: 0 5px;
        }
        &.prev{
            border-radius: 5px 0;
        }

    }
}

.slick-slider-dots-container{
    @include absolute-center(x);
    top: auto;
    bottom: -20px;
}


.slick-dots {
    @include reset-list;
    @include display-inline-flex;
    @include transition;

    li {
        .slick-dots-circle {
            height: 12px;
            width: 12px;
            // background-color: $white-color;
            margin: 5px;
            border: 2px solid rgba($brand-gray-04, 0.5);

            @include transition;
            @include border-radius(50%);
        }

        &.slick-active {
            .slick-dots-circle {
                width: 24px;
                @include border-radius(10px);
                border: 2px solid $brand-primary;
            }
        }
    }
}

.slick-dotted
{
    .slick-dots
    {
        @include media-breakpoint-down(xs)
        {
            @include reset-position;
            @include justify-content(center);
        }
    }
}

/*----------  Nav-Tabs  ----------*/
.nav-tabs{
    border: none;


    .nav-link{
        border: none;

        &:hover,&:focus,&:active, &.active{
            color: $white-color;
            background-color: transparent;
            border: none;
        }
    }
}

  /* Bubble Bottom */
  .hvr-bubble-bottom {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
    position: relative;

    &:before{
        pointer-events: none;
        position: absolute;
        z-index: -1;
        content: '';
        border-style: solid;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        left: calc(50% - 10px);
        bottom: 0;
        border-width: 10px 10px 0 10px;
        border-color: #e1e1e1 transparent transparent transparent;
    }

    &:hover,
    &:focus,
    &:active{
        &:before{
            -webkit-transform: translateY(10px);
            transform: translateY(10px);
        }
    }
  }

/*----------  Scroll To Top  ----------*/
  .scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    border-radius: 50%;
    margin: 0;
    text-align: center;
    z-index: 999999999;
    cursor: pointer;
	display:none;
    opacity: .8;
    transition: .3s ease-in;

    i:before {
        color: #ff9a00;
        font-size: 20px;
        margin: 0;
        line-height: 45px;
    }

    &:hover {
        opacity: 1;
        transition: .3s ease-in;
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

}

.pagination{
    @include display-inline-flex($jc: center);
}