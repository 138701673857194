.navbar-brand{
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	z-index: 9999;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;

	img{
		max-width: 300px;
		@include media-breakpoint-down(sm)
		{
			width: 200px;
		}
	}
}

.navbar-wrapper{
	background-color: $white-color;

	.navbar-light{

		.navbar-toggler{
			border-color: transparent;
		}

	}
}

.navbar-light .navbar-nav{
	.show>.nav-link,
	.active>.nav-link,
	.nav-link.show,
	.nav-link.active{
		color: #8373ce;
	}
}


.navbar{
	padding: 0;

	&.navbar-expand-lg{
		.navbar-collapse{
			>ul{
				>li{
					display: inline-block;
					position: relative;

					&.active{
						> a{
							color: #8373ce;
						}
					}

					>a{
						display: inline-block;
						text-transform: capitalize;
						padding: 24px 20px 18px;
						@include font-s($font-md, $weight-medium, $font-md + rem-calc(2), $brand-gray-01);
						@include transition;

					}

					@include media-breakpoint-down(md)
					{
						display: block;
						a{
							padding: 10px 15px;
							display: block;
							background-color: $black-color;
							color: $white-color;

							&:hover,
							&:focus,
							&:active{
								color: $white-color;
							}
						}

						&.dropdown{
							.dropdown-menu{
								margin: 0;
								padding: 0;
								width: 100%;
							}
						}
					}
				}

				> li:nth-child(1) > a {
					border-top: 2px solid #8373ce;

					&:before {
						border-color: #8373ce transparent transparent transparent;
					}

				}

				> li:nth-child(2) > a {
					border-top: 2px solid #f8b54d;

					&:before {
						border-color: #f8b54d transparent transparent transparent;
					}
				}

				> li:nth-child(3) > a {
					border-top: 2px solid #fc5b4e;

					&:before {
						border-color: #fc5b4e transparent transparent transparent;
					}
				}

				> li:nth-child(4) > a {
					border-top: 2px solid #adca69;

					&:before{
						border-color: #adca69 transparent transparent transparent;
					}
				}

				> li:nth-child(5) > a {
					border-top: 2px solid #35a3f0;

					&:before{
						border-color: #35a3f0 transparent transparent transparent;
					}
				}

				> li:nth-child(6) > a {
					border-top: 2px solid #c66196;

					&:before{
						border-color: #c66196 transparent transparent transparent;
					}
				}

				> li:nth-child(7) > a {
					border-top: 2px solid #84bed6;

					&:before{
						border-color: #84bed6 transparent transparent transparent;
					}
				}

				> li:nth-child(8) > a {
					border-top: 2px solid #ae84d6;

					&:before{
						border-color: #ae84d6 transparent transparent transparent;
					}
				}

				> li:nth-child(9) > a {
					border-top: 2px solid #84d6d5;

					&:before{
						border-color: #84d6d5 transparent transparent transparent;
					}
				}
			}
		}
	}
}




.navbar{
	.navbar-collapse{
		> ul{
			> li {
				> a{

					@include transform(translateZ(0));
					overflow: hidden;

					&:hover{
						color: #8373ce;
					}

					&:before {
						pointer-events: none;
						position: absolute;
						z-index: -1;
						content: '';
						border-style: solid;
						-webkit-transition-duration: 0.3s;
						transition-duration: 0.3s;
						-webkit-transition-property: transform;
						transition-property: transform;
						left: calc(50% - 10px);
						top: -10px;
						border-width: 10px 10px 0 10px;
					}

					&:hover:before,
					&:focus:before,
					&:active:before {
						-webkit-transform: translateY(8px);
						transform: translateY(8px);
					}

				}

				&.active > a {
					color: #8373ce;

					&:before {
						-webkit-transform: translateY(8px);
						transform: translateY(8px);
					}
				}

			}

		}

	}

}

/** On hover show dropdown menu **/
.dropdown:hover>.dropdown-menu {
	display: block;
	margin: 0;
	@include transition(all .5s ease);
}

.dropdown>.dropdown-toggle:active {
	/*Without this, clicking will make it sticky*/
	pointer-events: none;
}

/** Dropdown menu **/

.dropdown{
	.dropdown-menu{
		background: #fff;
		width: 220px;
		border: 1px solid #EEE;
		transition: all .5s ease;
		@include box-shadow(0 13px 42px 11px rgba(0,0,0,.05));

		>a.dropdown-item{
			display: block;
			text-transform: capitalize;
			color: #232323;
			padding: 10px 19px;
			transition: all .3s ease;
			text-align: left;
			@include font-s($font-md, $weight-medium, $font-md + rem-calc(2), $brand-gray-01);
			@include transition;

			&:hover{
				background: #F9F9F9;
				color: #8373ce;
				padding-left: 23px;
			}
		}
	}
}




/*=============== header top =============*/
header {

	.container{
		@include position;
	}

	.main-navbar{
		margin-top: 60px;

		@include media-breakpoint-down(md)
		{
			margin: 0;
		}
	}

	.header-top {
		// position: relative;
		@include position(absolute, 5, $top: 0, $bottom: auto, $left: auto, $right: 15px);
		z-index: 9;
		text-align: right;

		@include media-breakpoint-down(md)
		{
			@include position;
			display: block;
		}

		@include media-breakpoint-down(xs)
		{
			display: none;
		}

		.contact {
			position: relative;
			display: inline-block;
			font-size: 15px;
			color: #999;
			font-family: 'dosis';
			padding: 20px 20px;

			span {
				padding-right: 10px;
			}
		}

		.register {
			position: relative;
			display: inline-block;
			padding: 19px 20px;

			a {
				font-size: 16px;
				color: #999;
				font-family: 'dosis';
			}
		}

		.top-search {
			position: relative;
			padding: 19.5px 20px;
			cursor: pointer;
			display: inline-block;

			span.search:before {
				color: #232323;
				font-size: 17px;
				margin: 0;
			}

			.search-box {
				position: absolute;
				width: 250px;
				background: #ff9a00;
				top: 100%;
				right: 0;
				list-style: none;
				margin: 0;
				padding: 0;
				padding: 10px;
				opacity: 0;
				visibility: hidden;
				transition: all 0.5s ease-in;
				z-index: 9999;

				form {
					background: #fff;
				}
				li {
					list-style: none;

					input {
						width: 78%;
						background-color: transparent;
						border: none;
						outline: none;
						height: 40px;
						padding-left: 15px;
						color: #666;
					}
					input::-webkit-input-placeholder,
					input:-moz-placeholder,
					input:-ms-input-placeholder
					{
						color: #999;
						font-weight: normal;
						font-family: 'Raleway', sans-serif;
						font-size: 14px;
					 }

					 button {
						background: none;
						outline: none;
						color: #A9A9A9;
						border: none;
						width: 30px;
						height: 40px;
						margin-right: 5px;
					}
				}
			  }

			  &:hover .search-box {
				transition: .5s ease-in-out;
				visibility: visible;
				opacity: 1;
			}
		}

		.contact:after,
		.register:after,
		.top-search:after {
			position: absolute;
			content: "";
			bottom: 0;
			right: 0;
			height: 65px;
			width: 1px;
			background: #f7f7f7;
		}
	}

}


/** Sticky Header **/
.site-header{
	&.fixed{

		@include position(fixed, 999);
		background-color: $white-color;

		.navbar-wrapper{
			@include box-shadow(0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12));

			.navbar{
				// padding-bottom: 10px;

				.main-navbar{
					margin: 10px 0;
				}
			}
		}

		.header-top{
			display: none;
			@include transition;
		}

		.navbar-brand{
			margin: 5px 0;

			img{
				width: 169px;
			}

			.navbar-toggler{
				margin-top: 20px;
			}
		}

	}

}