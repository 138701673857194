/* form input classes */
.form-group,
.form-check,
fieldset {
    margin-bottom: 10px;
}

input,
textarea,
select {

    &.form-control {
        height: auto;
        border-radius: 6px;
        border: 1px solid $brand-gray-03;
        background-color: $white-color;
        caret-color: $brand-danger;
        padding: 10px 14px;
        @include font-p($font-base, $weight-regular, 1.1, $brand-primary);
        @include placeholder($brand-gray-01);

        &:active,
        &:focus {
            border-color: $brand-danger;
            color: $brand-primary;
        }
    }
}

.form-check-label {
    @include font-p($font-base, $weight-light, 1.38, $brand-primary);

    a,
    span {
        color: $brand-danger;
    }
}

fieldset {
    legend {
        @include font-s($font-xs, $weight-light, 1.2, $brand-primary);
        text-transform: uppercase;
        margin-bottom: 24px;
    }
}

.form-header {
    text-align: center;
    margin-bottom: 70px;

    h4, .form-title {
        @include font-s-46($weight-medium, 1.22, $brand-primary);
        margin-bottom: 6px;
    }

    .form-sub-title {
        @include font-p-24($weight-light, 1.33, $brand-gray-01);
    }

    @include media-breakpoint-down(sm)
    {
        margin-bottom: 30px;
        .form-sub-title {
            font-size: $font-base;
        }
    }
}

.agreement-wrapper {
	margin-top: 20px;
	width: 100%;
}

.form-footer {
    padding-top: 20px;
    text-align: center;
}


.item-toggle {
    @include display-inline-flex(normal);
    margin-bottom: 20px;

    .toggle-text {
        @include font-p($font-base, $weight-regular, 1.38, $brand-primary);

        a {
			@include font-p($font-base, $weight-regular, 1.38, $brand-danger);

			&:hover, &:active, &:focus
			{
				color: $brand-primary;
			}
        }
    }
}

.toggle {
	margin: 0;
	margin-right: 24px;
	width: 24px;
	height: 24px;
    cursor: pointer;

    @include position;
    @include user-select(none);
    @include display-inline-flex(normal);
    @include font-s(32px, $weight-regular, 1.3, $white-color);


    span {
        display: block;
    }

    .toggle-input {
        @include opacity(0);
        @include pointer-events(none);
        @include position(absolute, -1, 0, 0);
    }

    .toggle-icon {
        overflow: hidden;
        // margin-right: 24px;

        @include position;
        @include flex(0 0 24px);

        &:before {
            @include opacity(0);
            // @include transition;
            @include absolute-center;
            @include pointer-events(none);
        }

    }


    .toggle-input:checked+.toggle-icon {
        &:before {
            @include opacity(1);
        }
    }

    &.toggle-checkbox {
        .toggle-icon {
            background-color: $brand-primary;

            @include border-radius(5px);

            &:before {
                content: '\f00c';

                // @include font-ion($font-xxl, $white-color);
                @include font-laf(16px, $weight-bold, 1.3, $white-color);

            }
        }
    }

    &.toggle-radio {
        .toggle-icon {
            border: 24px solid $brand-primary;

            @include bg-color(transparent);
            @include border-radius(24px);

            &:before {
                content: '';
                width: 24px;
                height: 24px;
                background-color: $brand-secondary;

                @include border-radius(24px);
            }

        }
    }
}

.toggle-text {
	@include flex(1);
	cursor: pointer;
}
.filter-group {
    input,
    textarea,
    select {

        &.form-control {
            height: 46px;
            border: 0;
            background-color: $white-color;
            padding: 14px 20px;
            @include font-s-14($weight-regular, 1.43, $brand-primary);

            &:active,
            &:focus {
                color: $brand-primary;
                background-color: transparent;
            }
        }
	}

	.atn-link {
		@include font-s-14($weight-regular, 1.43, $brand-primary);
		border-bottom: 2px solid $brand-danger;
		@include position;
		// @include opacity(0);
		// @include font-inherit;
		&:after
		{
			content: '';
			height: 2px;
			width: 100%;
			background: $brand-primary;
			transform-origin: left top;

			@include transition;
			@include transform(scaleX(0));
			@include position(absolute, 2, $bottom: -2px, $left: 0);
		}
		&:hover, &:active, &:focus
		{
			&:after
			{
				@include transform(scaleX(1));
			}
		}
	}

    .input-group {
        border-radius: 6px;
        border: 1px solid $brand-gray-03;
        @include transition;
        @include display-inline-flex;

        svg, g, line{
            @include transition;
        }

        &:focus, &:active, &:focus-within{
            border: 1px solid $brand-danger;
        }

        .input-group-text{
            @include transition;
        }

        &:hover{
            .input-group-text{
                color: $brand-primary;

                svg{
                    fill: $brand-primary;

                    g, line{
                        stroke: $brand-primary;
                    }
                }
            }
        }
    }

    .input-group-prepend {
        @include position(relative, auto);
        @include border-radius(6px 0 0 6px);
    }

    .input-group-text {
        background-color: $white-color;
        color: $brand-gray-01;
        border: 0;
        height: 46px;
        padding-left: 20px;
        padding-right: 20px;
        @include border-radius(6px 0 0 6px);
        @include font-s-14($weight-regular, 1.43, $brand-gray-01);

        &:before {
            content: '';
            height: calc(100% - 20px);
            width: 1px;
            background-color: $brand-gray-01;

            @include absolute-center(y) {
                left: auto;
                right: 0;
            }
        }
    }

    .bootstrap-select {
        min-width: 150px;

        .btn {
            @include font-s-14($weight-regular, 1.43, $brand-primary);
            @include button-theme($brand-primary, transparent, transparent);
			padding-top: 0;
			padding-bottom: 0;
            &:hover,
            &:active,
            &:focus {
                box-shadow: none !important;
                outline: 0 !important;
            }
        }

        .dropdown-toggle {
            @include position(relative, 0);

            &:before {
                content: '\f107';
                font-family: $font-lineawesomefree;
                @include transition;
                font-weight: 900;

                @include absolute-center(y) {
                    left: auto;
                    right: 12px;
                }
            }

            &:after {
                @include opacity(0);
            }

        }

        &.show {
            .dropdown-toggle {
                &:before {
                    @include transform(rotate(180deg) translateY(50%));
                }
            }
        }

        div.dropdown-menu {
            //@include position(absolute, 9, $top:0, $left:auto, $right:0);
            top: 4px !important;
            left: auto !important;
            right: 0 !important;

            a {
                .text {
                    @include font-s-14($weight-regular, 1.43, $brand-primary);
                }
            }

            li {

                &.selected,
                &.active {
                    background-color: $brand-light;

                    a {

                        &.selected,
                        &.active {
                            background-color: $brand-light;
                        }
                    }
                }

                &.disabled {
                    a {
                        .text {
                            @include font-s-14($weight-regular, 1.43, $brand-gray-01);
                        }
                    }
                }
            }
        }

        &.text-center {

            .filter-option-inner-inner {
                text-align: center;
            }
        }
    }

    .no-toggle {
        .dropdown-toggle {
            &:after, &:before {
                opacity: (0);
            }
        }
    }

    .fav-icon-group {
        @include position(relative, 0);

        .badge {
            border-radius: 10px;
            @include font-s-14($weight-regular, 1.03, $white-color);

            @include absolute-center(y) {
                left: auto;
                right: -12px;
            }

            &.badge-danger {
                background-color: #ff2300;
            }
		}

		&.active {
			svg {
				fill: $brand-danger;

				path, g{
					stroke: $brand-danger;
					fill: $brand-danger;
				}
			}
		}
    }

    .price-range-selector {
        margin-left: 1px;
        @include display-inline-flex;
		@include position;

		.form-control {
			max-width: 150px;
			text-align: center;
		}

        .sep {
            width: 22px;

            &:before {
                content: '';
                width: 22px;
                height: 1px;
                background-color: $brand-gray-01;
                margin: 0 auto;

                @include absolute-center();
            }
        }
    }

    .button-switch {
        margin: 5px 20px 0;
        height: 22px;
        position: relative;
        width: 61px;


        .lbl-off,
        .lbl-on {
            cursor: pointer;
            display: block;
            @include font-s-14($weight-regular, 0.25, $white-color);
            position: absolute;
            top: 8 / 16 * 1em;
            @include transition;
        }

        .lbl-off {
            left: 0.8em;
        }

        .lbl-on {
            color: $white-color;
            opacity: 0;
            right: 0.8em;
        }

        .switch {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 0;
            font-size: 1em;
            left: 0;
            line-height: 0;
            outline: none;
            position: absolute;
            top: 0;
            width: 0;

            &:before,
            &:after {
                content: '';
                font-size: 1em;
                position: absolute;
            }

            &:before {
                border-radius: 20 / 16 * 1em;
                background: $brand-gray-01;
                height: 22px;
                width: 61px;
                left: 4 / 16 * -1em;
                top: 3 / 16 * -1em;
                @include transition;
            }

            &:after {
                border-radius: 50%;
                background: $white-color;
                height: 15px;
                width: 15px;
                transform: translate(40 / 17 * 1em, 0);
                @include transition;
            }

            &:checked {
                &:after {
                    transform: translate(0, 0);
                }

                &~.lbl-off {
                    opacity: 0;
                }

                &~.lbl-on {
                    opacity: 1;
                }
            }

            &#switch-orange {
                &:checked:before {
                    background: $brand-danger;
                }
            }
        }
    }

    .filter-checkbox-wrapper {
        margin: 0 12px;
        cursor: pointer;
        @include display-inline-flex;

        .filter-checkbox {
            @include position;

            input {
                @include opacity(0);
				@include position(absolute, 2, 0, 0);
				@include pointer-events(none);
            }

            label {
                margin: 0;
				padding: 4px 8px;
                cursor: pointer;

				@include font-s-14($weight-regular, 1.03, $brand-primary);
            }

            &.checked {
                background-color: rgba($brand-danger, 0.1);
                border-top: 1px solid $brand-danger;
                border-bottom: 1px solid $brand-danger;


                &.first-child {
                    border-left: 1px solid $brand-danger;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &.last-child {
                    border-right: 1px solid $brand-danger;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

    }

    @include media-breakpoint-down(xs) {
        width: 100%;
        .price-range-selector {
            width: 100%;
        }
        .bootstrap-select {
            min-width: auto;
        }
        .input-group-text {
            white-space: initial;
            text-align: left;
        }

        &.filter-fav-icon {
            width: auto;
        }
    }
}
